import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { CircleLoader, BarLoader, PulseLoader } from 'react-spinners';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import SendIcon from '@mui/icons-material/Send';
import 'react-toastify/dist/ReactToastify.css';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import './Row.css';
import './Zoompage.css';
// @muia
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
} from '@chakra-ui/react';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  TextField,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { onSnapshot, doc, deleteDoc, collection, updateDoc, getDocs, getDoc } from "firebase/firestore";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { db } from '../firebase';
import { UserAuth } from '../contexts/AuthContext';
// ----------------------------------------------------------------------
const PARENT = window.location.host.split('.')[0];

function formatTime(value) {
  const timeParts = value.split(':');
  const hours = parseInt(timeParts[0], 10);
  const minutes = parseInt(timeParts[1], 10);

  let formattedTime = '';
  if (hours === 0) {
    formattedTime = `12:${minutes.toString().padStart(2, '0')} AM`;
  } else if (hours < 12) {
    formattedTime = `${hours}:${minutes.toString().padStart(2, '0')} AM`;
  } else if (hours === 12) {
    formattedTime = `12:${minutes.toString().padStart(2, '0')} PM`;
  } else {
    formattedTime = `${hours - 12}:${minutes.toString().padStart(2, '0')} PM`;
  }

  return formattedTime;
}

export default function Zoompage() {
  const [meetings, setMeetings] = useState([]);
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);

  const { user } = UserAuth();

  function openZoom() {
    window.open('https://us05web.zoom.us/s/8698604655?pwd=clRtSVgrcnRBbDZvMEtpU0cwRFduQT09#success', '_blank');
  }

  async function addMeeting() {
    setTitle('');
    setDate('');
    setTime('');
    const tempMeetings = meetings;
    tempMeetings.push({mTitle: title, mDate: date, mTime: time});
    setMeetings(tempMeetings);
    await updateDoc(doc(db, "Companies", PARENT), {
      meetings: tempMeetings
    })
    loadMeetings();
  }

  async function loadMeetings() {
    const cSnap = await getDoc(doc(db, "Companies", PARENT))
    if (cSnap.data().meetings) {
      console.log("Meetings", cSnap.data().meetings);
      setMeetings(cSnap.data().meetings);
    }
  }

  async function enforcePermissions(type) {
    if (type === "Admin") {
      setIsAdmin(true)
    }  else {
      setIsAdmin(false)
    }
    loadMeetings();
  }

  async function deleteMeeting(index) {
    const tempMeetings = meetings;
    tempMeetings.splice(index, 1);
    setMeetings(tempMeetings);
    await updateDoc(doc(db, "Companies", PARENT), {
      meetings: tempMeetings
    })
    loadMeetings();
  }

  useEffect(() => {
    user.getIdTokenResult().then(async (idTokenResult) => {
      enforcePermissions(idTokenResult.claims.accountType);
    });
  }, [])

  return (
    <>
      <Helmet>
        <title> Zoom | StrongMentorship </title>
      </Helmet>

    
      <Container>
        <Button variant='contained'
          onClick={() => openZoom()}><OpenInNewIcon />
          &nbsp;&nbsp;
          Open Meeting
        </Button>

        {isAdmin && (
          <>
            <div style={{marginTop:"15px"}} />
            <h2>Set New Meeting</h2>
            <div style={{marginTop:"15px"}} />
            Meeting Title:
            <div style={{marginTop:"5px"}} />
            <TextField onChange={e => setTitle(e.target.value)} value={title}/>
            <div style={{marginTop:"15px"}} />
            Date:
            <div style={{marginTop:"5px"}} />
            <TextField type='date' onChange={e => setDate(e.target.value)} value={date}/>
            <div style={{marginTop:"15px"}} />
            Time:
            <div style={{marginTop:"5px"}} />
            <TextField type='time' onChange={e => setTime(e.target.value)} value={time}/>
            <div style={{marginTop:"15px"}} />
            <Button onClick={() => addMeeting()}>Add Meeting</Button>
          </>
        )}

        <div style={{marginTop:"15px"}} />
        {meetings.map((meeting, key) => (
          <div className='MeetingRow'>
            <div style={{color:"lightgray", marginRight:"10px"}}>Title: </div><div> {meeting.mTitle}</div>
            <div style={{marginLeft:"25px"}} />
            <div style={{color:"lightgray", marginRight:"10px"}}>Date: </div><div> {meeting.mDate}</div>
            <div style={{marginLeft:"25px"}} />
            <div style={{color:"lightgray", marginRight:"10px"}}>Time: </div><div> {formatTime(meeting.mTime)}</div>
            {isAdmin && (
              <button style={{marginLeft:"auto", marginRight:"10px"}} onClick={() => deleteMeeting(key)}>Delete</button>
            )}
          </div>
        ))}
      </Container>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        />
    </>
  );
}
