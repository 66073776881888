import { useEffect, useState } from 'react';
import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
// routes
import Router from './routes';
import NoAffRouter from './noAffRoutes'
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';
// ----------------------------------------------------------------------

export default function App() {
  const [foundSub, setFoundSub] = useState(false);

  useEffect(() => {
    const currentSubdomain = window.location.hostname.split('.')[0];
    if (currentSubdomain === 'compass') {
      setFoundSub(true)
    }
    console.log(currentSubdomain)
  }, []);

  return (
      <ThemeProvider>
        <ScrollToTop />
        <StyledChart />
        {foundSub ? <Router /> : <NoAffRouter />}
      </ThemeProvider>
  );
}
