import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import { Container, Stack, Typography, ToggleButton, ToggleButtonGroup, Button, TextField } from '@mui/material';
import { PuffLoader, PulseLoader } from 'react-spinners';
import { ToastContainer, toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import './MenteeCurriculum.css'
// components
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getDoc, getDocs, collection, doc, setDoc, updateDoc, onSnapshot, deleteDoc } from 'firebase/firestore';
import { db, deleteUser } from '../firebase';
import { UserAuth } from '../contexts/AuthContext';
import inviteUser from '../intake/inviteUser';
import './Viewers.css'

// ----------------------------------------------------------------------
const PARENT = window.location.host.split('.')[0];

export default function Viewers() {
  const [onPage, setOnPage] = useState(1);
  const [newSectionName, setNewSectionName] = useState('');
  const [newCheckName, setNewCheckName] = useState('');
  const [areYouSure, setAreYouSure] = useState('');
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [curriculum, setCurriculum] = useState([]);
  const [totalCurriculum, setTotalCurriculum] = useState([]);
  const [selectedAccordion, setSelectedAccordion] = useState('');
  const [newSectionOrder, setNewSectionOrder] = useState(0);
  const [viewerList, setViewerList] = useState([])
  const { user } = UserAuth();

  const [viewerFirst, setViewerFirst] = useState('');
  const [viewerLast, setViewerLast] = useState('');
  const [viewerEmail, setViewerEmail] = useState('');

  function successToast() {
    toast.success('Viewer invited! They must now accept the invitation email.', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
  }

  async function enforcePermissions(type, page) {
    if (type !== "Admin") {
      window.location.href = "/404";
    } else {
      const companySnap = onSnapshot(doc(db, "Companies", PARENT), async (companyDoc) => {
        if (companyDoc.data().viewers) {
          const vList = [];
          await Promise.all(
            companyDoc.data().viewers.map(async (viewer) => {
              const viewerSnap = await getDoc(doc(db, "Companies", PARENT, "Users", viewer.value));
              const hasLogged = viewerSnap.data().hasLoggedIn !== undefined;
              vList.push({label: viewer.label, value: viewer.value, hasLoggedIn: hasLogged});
            })
          );
          setViewerList(vList);
        }
      });
      // const vList = [];
      // const userSSnap = await getDocs(collection(db, "Companies", PARENT, "Users"));
      // userSSnap.forEach((userDoc) => {
      //   if (userDoc.data().location.length === 1) {
      //     if (userDoc.data().email !== 'elbowfun@gmail.com' && userDoc.data().email !== 'sam@sam.com' && userDoc.data().email !== 'craig@strongrealtor.com') {
      //       vList.push({label: userDoc.data().email, value: userDoc.id});
      //     }
      //   }
      // });
      // await updateDoc(doc(db, "Companies", PARENT), {
      //   viewers: vList
      // });
      setLoading(false);
    }
  }

  useEffect(() => {
    user.getIdTokenResult().then(async (idTokenResult) => {
      enforcePermissions(idTokenResult.claims.accountType, onPage);
    });
  }, []);

  async function inviteViewer() {
    setLoading(true);
    await inviteUser(viewerFirst, viewerLast, viewerEmail, 'Viewer', PARENT, [PARENT]).then(() => {
      setViewerFirst('');
      setViewerLast('');
      setViewerEmail('');
      successToast();
      setLoading(false);
    }).catch((error) => {
      console.log(error);
      toast.error("A user with this email already exists in the system", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
    });
  }

  async function removeViewer(id) {
    setDeleteLoading(true);
    const newList = [];
    viewerList.forEach((viewer) => {
      if (viewer.value !== id) {
        newList.push(viewer);
      }
    });
    await updateDoc(doc(db, "Companies", PARENT), {
        viewers: newList
    });
    await deleteUser({uid : id, 
      parent: PARENT,
      userLocation: [],
      accountType: "Viewer"}).then(() => {
      setDeleteLoading(false);
      setAreYouSure('');
      toast.info('Viewer has been removed', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
  }

  function promptRemove(id) {
    setAreYouSure(id);
  }
  

  return (
    <>
      <Helmet>
        <title> Admin: Manage Viewers</title>
      </Helmet>

      {areYouSure !== '' && (
        // position center absolute
        <div style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#2d2d2d",
            width: "400px",
            height: "150px",
            borderRadius: "10px",
            zIndex: "1000",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}>
          {deleteLoading ? 
          
          <PulseLoader speedMultiplier="2" color="#6594e3"/>
          :
          <div className="areYouSureBox">
            <div className="areYouSureText">
              Are you sure you want to remove this viewer?
            </div>
            <div style={{marginTop:"30px"}} />
            <div className="areYouSureButtons">
              <Button variant="contained" style={{width:"180px", height:"45px", marginRight:"10px"}} onClick={() => removeViewer(areYouSure)}>
                Yes
              </Button>
              <Button variant="contained" style={{width:"180px", height:"45px"}} onClick={() => setAreYouSure('')}>
                No
              </Button>
            </div>
          </div>}
        </div>
      )}
      <div style={{textAlign:"center"}}>
        <TextField placeholder ={'First Name'} value={viewerFirst} onChange={(e) => setViewerFirst(e.target.value)}/>
        <div style={{marginTop:"10px"}} />
        <TextField placeholder ={'Last Name'} value={viewerLast} onChange={(e) => setViewerLast(e.target.value)}/>
        <div style={{marginTop:"10px"}} />
        <TextField placeholder ={'Email'} value={viewerEmail} onChange={(e) => setViewerEmail(e.target.value)}/>
        <div style={{marginTop:"10px"}} />
        <Button variant="contained" disabled={loading} style={{width:"180px", height:"45px"}} onClick={() => inviteViewer()}>
          {loading ?
          <PuffLoader color="#ffffff" size="40px"/>
          : 'Invite New Viewer'
          }
        </Button>
      </div>

      <Container style={{display:"flex"}}>
        <div style={{margin:"auto"}}>
            {viewerList.map((viewer, index) => {
            return(
                <div className="viewer-card">
                    <div className="viewer-card-left">
                        <div className="viewer-card-name" style={viewer.hasLoggedIn ? {color:"white"} : {color:"yellow"}}>
                            {viewer.label}
                        </div>
                    </div>
                    <div className="viewer-card-right">
                        <button style={{color:"red"}} onClick={()=>promptRemove(viewer.value)}>
                            Remove
                        </button>
                    </div>
                </div>
            )
          })}
        </div>
      </Container>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        />
    </>
  );
}
