/* eslint-disable */
import { setDoc, updateDoc, doc, getDoc } from "firebase/firestore";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { inviteNewUser, sendEmail, setCustomUserClaims, db } from "../firebase";
import { inviteEmailHTML } from "./InviteEmailHTML";

function makeid(length) {
    let result           = '';
    const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export default async function inviteUser(userFirstName, userLastName, userEmail, userRole, userCompany, userLocation) {
    const tempPass = makeid(15);
    const auth = getAuth();

    await inviteNewUser({
        email: userEmail,
        password: tempPass,
        accountType: userRole,
        parentName: userCompany,
        firstName: userFirstName,
        lastName: userLastName,
        userLocation: userLocation
    }).then(() => {
        const actionCodeSettings = {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be in the authorized domains list in the Firebase Console.
            url: `https://${userCompany.toLowerCase()}.strongmentorship.com`,
            // This must be true.
            handleCodeInApp: true,
            expireAfterSeconds: 259200
        };
        sendPasswordResetEmail(auth, userEmail, actionCodeSettings)
    });
}