import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { CircleLoader, BarLoader, PulseLoader } from 'react-spinners';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import SendIcon from '@mui/icons-material/Send';
import 'react-toastify/dist/ReactToastify.css';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import './Row.css';
// @muia
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
} from '@chakra-ui/react';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  TextField,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
// firebase
import { onSnapshot, doc, deleteDoc, collection, updateDoc, getDocs, getDoc } from "firebase/firestore";
import EditName from './EditName';
import { UserAuth } from '../contexts/AuthContext';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import AddNew from './AddNew';
import AddManager from './AddManager';
import ViewManagers from './ViewManagers';
import MassEmailModal from '../sections/@dashboard/user/MassEmailModal';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';

import { db, deleteUser, getAllUserUids } from '../firebase';
import MenteeModal from './MenteeModal/MenteeModal';

// ----------------------------------------------------------------------
const PARENT = window.location.host.split('.')[0];

const TABLE_HEAD_STATE = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'type', label: 'Account Type', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'lgcount', label: 'Login Count', alignRight: false },
  { id: 'llog', label: 'Last Login', alignRight: false },
  { id: '' },
];

export default function Userpage() {
  const auth = getAuth();

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentList, setCurrentList] = useState([]);

  const [onPage, setOnPage] = useState(0);
  const [massOpen, setMassOpen] = useState(false);

  const { user } = UserAuth();

  function errToast(text) {
    toast.error(text, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      });
  }

  async function retrieveData() {
    setLoading(true)
    const tempList = [];
    const userCollection = await getDocs(collection(db, "Companies", PARENT, "Users"));
    userCollection.forEach(async (doc) => {
      tempList.push([doc.id, doc.data()]);
    });
    setCurrentList(tempList);
    setLoading(false);

    // clean out invalid users if needed
    // await getAllUserUids().then(async (arr) => {
    //   const valUIDS = arr.data;
    //   await Promise.all(tempList.map(async (uid) => {
    //     const entry = uid[0];
    //     if (!valUIDS.includes(entry)) {
    //       await deleteDoc(doc(db, "Companies", PARENT, "Users", entry));
    //       console.log("Invalid", entry)
    //     } else {
    //       console.log("Valid", entry);
    //     }
    //   }));
    // })
    // const tempMenteeList = [];
    // const statesSnap = await getDocs(collection(db, "Companies", PARENT, "States"));
    // await Promise.all(
    //   statesSnap.docs.map(async (doc1) => {
    //     const regionsSnap = await getDocs(collection(db, "Companies", PARENT, "States", doc1.id, "Regions"));
    //     await Promise.all(
    //       regionsSnap.docs.map(async (doc2) => {
    //         const officesSnap = await getDocs(collection(db, "Companies", PARENT, "States", doc1.id, "Regions", doc2.id, "Offices"));
    //         await Promise.all(
    //           officesSnap.docs.map(async (doc3) => {
    //             const mentorsSnap = await getDocs(collection(db, "Companies", PARENT, "States", doc1.id, "Regions", doc2.id, "Offices", doc3.id, "Mentors"));
    //             await Promise.all(
    //               mentorsSnap.docs.map(async (doc4) => {
    //                 const menteesSnap = await getDocs(collection(db, "Companies", PARENT, "States", doc1.id, "Regions", doc2.id, "Offices", doc3.id, "Mentors", doc4.id, "Mentees"));
    //                 await Promise.all(
    //                   menteesSnap.docs.map(async (doc5) => {
    //                     tempMenteeList.push({id: doc5.id, 
    //                       email: doc5.data().mEmail, 
    //                       first: doc5.data().mFirstName, 
    //                       last: doc5.data().mLastName, 
    //                       location: [PARENT, doc1.id, doc2.id, doc3.id, doc4.id]});
    //                   }
    //                 ));
    //               }
    //             ));
    //           }
    //         ));
    //       }
    //     ));
    //   })
    // );
    // console.log(tempMenteeList);
    // await updateDoc(doc(db, "Companies", PARENT), 
    //   {menteeCache: tempMenteeList}
    // );
  }

  function enforcePermissions(type, page) {
    if (type !== "Admin") {
    window.location.href = "/404";
  }  else {
      retrieveData();
    }
  }


  useEffect(() => {
    user.getIdTokenResult().then(async (idTokenResult) => {
      enforcePermissions(idTokenResult.claims.accountType, onPage);
    });
  }, []);

  function CustomTableCell(props) {
    const cellStyle = {
      borderBottom: '2px solid #333333',
    };

    return <TableCell style={cellStyle} {...props} />;
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = currentList.map((n) => n[0]);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    console.log(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  function getElementName(element, onPage) {
    return (`${element[1].firstName} ${element[1].lastName}`);
  }

  function resendInvite(email) {
    const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: `https://${PARENT.toLowerCase()}.strongmentorship.com`,
        // This must be true.
        handleCodeInApp: true,
        expireAfterSeconds: 259200,
    };
    sendPasswordResetEmail(auth, email, actionCodeSettings)
    .then(() => {
        // Password reset email sent!
        // ..
    })
    .catch((error) => {
        const errorCode = error.code;
        console.log(errorCode);
        const errorMessage = error.message;
        console.log(errorMessage);
        // ..
    });
    toast.success('Invite email resent! Tell the user to check their spam folder if they do not receive an email.', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
  }

  function formatDate(timestamp) {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so we need to add 1
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  }

  function closeMassEmail() {
    setMassOpen(false);
    // toast.success('Mass email sent', {
    //           position: "bottom-center",
    //           autoClose: 5000,
    //           hideProgressBar: false,
    //           closeOnClick: true,
    //           pauseOnHover: true,
    //           draggable: true,
    //           progress: undefined,
    //           theme: "colored",
    // });
  }


  return (
    <>
      <Helmet>
        <title> Users | StrongMentorship </title>
      </Helmet>

    
      <Container>
        {massOpen && (<MassEmailModal finished={
          () => closeMassEmail()}
          currList={currentList}
          parent={PARENT} 
          closeModal={() => setMassOpen(false)}/>)}
        <Card> 
          <UserListToolbar 
            numSelected={selected.length} 
            filterName={filterName} 
            onFilterName={handleFilterByName} 
            location={0} 
            locLabel={"Users"}
            openMass={() => setMassOpen(true)}/>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD_STATE}
                  rowCount={currentList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {currentList.filter(element => getElementName(element, onPage)
                  .toLowerCase().includes(filterName.toLocaleLowerCase()))
                  .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage).map((val) => {
                    const selectedUser = selected.indexOf(val[0]) !== -1;
                    return (
                      <TableRow hover key={val[0]} tabIndex={-1} role="checkbox"
                                selected={selectedUser}>
                        <CustomTableCell padding="checkbox"/>
                        
                        <CustomTableCell component="th" scope="row" padding="none" style={{width:"300px", borderBottomColor:"#333333", borderBottomWidth:"2px"}}>
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {val[1].location.length === 1 ? <AdminPanelSettingsIcon />: <AccountCircleIcon />}
                            <Typography variant="subtitle2" noWrap>
                            {val[1].firstName} {val[1].lastName}
                            </Typography>
                          </Stack>
                        </CustomTableCell>

                        <CustomTableCell component="th" scope="row" padding="none" style={{width:"300px", borderBottomColor:"#333333", borderBottomWidth:"2px"}}>
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                            {val[1].email}
                            </Typography>
                          </Stack>
                        </CustomTableCell>

                        <CustomTableCell align="left" style={{width:"140px", borderBottomColor:"#333333", borderBottomWidth:"2px"}}>
                          {val[1].myOffices === undefined ? 
                            <>
                            {val[1].location.length === 1 ? <div style={{color:"#33ccff"}}>Admin/Viewer</div>
                            : val[1].location.length === 3 ? <div style={{color:"#9999ff"}}>Regional</div>:
                              <div>Mentor</div>
                            }
                            </>                        
                          :
                          <div style={{color:"#99ff99"}}>Office</div>
                          }
                        </CustomTableCell>

                        <CustomTableCell align="left" style={{width:"100px", borderBottomColor:"#333333", borderBottomWidth:"2px"}}>
                          <Label color={val[1].hasLoggedIn ? 'success' : 'warning'}>{val[1].hasLoggedIn ? "Logged In" : "Request Sent"}</Label>
                        </CustomTableCell>

                        <CustomTableCell align="left">
                            {val[1].loginCount !== undefined ? val[1].loginCount : 0}
                        </CustomTableCell>

                        <CustomTableCell align="left">
                            {val[1].lastActivity !== undefined ? formatDate(val[1].lastActivity) : "N/A"}
                        </CustomTableCell>

                        <CustomTableCell align="right">
                          {!val[1].hasLoggedIn && (<Button color='primary' size='small' style={{height:"20px"}} onClick={() => resendInvite(val[1].email)}>
                            <SendIcon /> &nbsp;&nbsp;Resend
                            </Button>)}
                        </CustomTableCell>
                      </TableRow>
                    );
                  })}

                  {loading && (
                    <div style={{width:"500px", margin:"20px", marginLeft:"80px"}}>
                      <PulseLoader speedMultiplier="2" color="#6594e3"/>
                    </div>
                  )}
                  
                </TableBody>
                
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={currentList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        />
    </>
  );
}
