import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
import { useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Checkbox } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { getDoc, updateDoc, doc, getDocs, collection } from 'firebase/firestore';
import './Sales.css';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { db } from '../firebase';
import inviteUser from '../intake/inviteUser';

// ----------------------------------------------------------------------
function formatNumberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const PARENT = window.location.host.split('.')[0];

export default function SalesPage() {
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getData() {
    setLoading(true);
    const parentSnap = await getDoc(doc(db, "Companies", PARENT));
    console.log(parentSnap.data().sales);
    const tempList = [];
    await Promise.all(
      parentSnap.data().sales.map(async (entry, index) => {
        console.log(entry);
        const menteeDoc = await getDoc(doc(db, 'Companies', entry.location[0], 'States', entry.location[1], 'Regions', entry.location[2], 'Offices', entry.location[3], 'Mentors', entry.location[4], "Mentees", entry.mentee));
        const mentorDoc = await getDoc(doc(db, 'Companies', entry.location[0], 'States', entry.location[1], 'Regions', entry.location[2], 'Offices', entry.location[3], 'Mentors', entry.location[4]));
        const officeDoc = await getDoc(doc(db, 'Companies', entry.location[0], 'States', entry.location[1], 'Regions', entry.location[2], 'Offices', entry.location[3]));
        const regionDoc = await getDoc(doc(db, 'Companies', entry.location[0], 'States', entry.location[1], 'Regions', entry.location[2]));
        const menteeName = `${menteeDoc.data().mFirstName} ${menteeDoc.data().mLastName}`
        const mentorName = `${mentorDoc.data().firstName} ${mentorDoc.data().lastName}`
        const officeName = `${officeDoc.data().Label}`
        const regionName = `${regionDoc.data().Label}`

        tempList.push({
          address: entry.address,
          mentee: menteeName,
          mentor: mentorName,
          office: officeName,
          region: regionName,
          state: entry.location[1],
          amount: `$${Number(entry.amount).toLocaleString()}`,
          date: entry.date,
          commission: `$${Number(entry.commission).toLocaleString()}`,
          paid: entry.paid || false,
          id: index + 1,
        });
      })
    );
    setSales(tempList);
    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, [])
  const theme = useTheme();

  const SalesGrid = (props) => {
    const [gridSales, setGridSales] = useState(props.sales);

    const updateData = async (updateID, updatePaid) => {
      const parentSnap = await getDoc(doc(db, "Companies", PARENT));
      const newSales = parentSnap.data().sales;
      newSales[updateID - 1].paid = updatePaid;
      await updateDoc(doc(db, "Companies", PARENT), {
        sales: newSales,
      });
    }

    const handleCellClick = (params, event) => {
      event.stopPropagation();
      // if (params.field === 'paid') {
      //   event.stopPropagation();
      // }
    };

    const handleCheckboxChange = (event, id) => {
      console.log(id);
      const tempSales = [...gridSales];
      tempSales[id - 1].paid = !tempSales[id - 1].paid;
      // console.log(updatedSales);
      setGridSales(tempSales);
      updateData(id, tempSales[id - 1].paid);
    };

    const columns = [
      { field: 'id', headerName: 'ID', width: 60 },
      {
        field: 'paid',
        headerName: 'Paid',
        width: 80,
        renderCell: (params) => (
          <Checkbox
            checked={gridSales[params.id-1].paid}
            onChange={(event) => handleCheckboxChange(event, params.id)}
            color="primary"
          />
        ),
      },
      { field: 'address', headerName: 'Address', width: 200 },
      { field: 'amount', headerName: 'Amount', width: 120 },
      { field: 'commission', headerName: 'Commission', width: 120 },
      { field: 'date', headerName: 'Date', width: 120 },
      { field: 'mentee', headerName: 'Mentee', width: 150 },
      { field: 'mentor', headerName: 'Mentor', width: 150 },
      { field: 'office', headerName: 'Office', width: 150 },
      { field: 'region', headerName: 'Region', width: 150 },
      { field: 'state', headerName: 'State', width: 150 },
    ];

    // Add an ID property to each sale
    const salesWithId = gridSales.map((sale, index) => ({
      ...sale,
      paid: sale.paid || false,
    }));


    return (
      <Container>
        <h4>Sales Data</h4>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={salesWithId}
            columns={columns}
            pageSize={5}
            getRowClassName={(params) =>
              gridSales[params.id-1].paid ? 'paid-row' : 'unpaid-row'
            }
            onCellClick={handleCellClick}
          />
        </div>
      </Container>
    );
  };

  return (
    <>
      <Helmet>
        <title> Sales | StrongMentorship </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          {loading ?
          <PulseLoader 
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          speedMultiplier="2" color="#6594e3"/>
          :
          <SalesGrid sales={sales} setSales={setSales} />
          }
        </Typography>
      </Container>
    </>
  );
}
