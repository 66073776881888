/* eslint-disable */
import { setDoc, updateDoc, doc, getDoc } from "firebase/firestore";
import { useEffect } from "react";
import { sendEmail, setCustomUserClaims, db } from "../firebase";
import { inviteEmailHTML } from "./InviteEmailHTML";

export default async function appointOfficeManager(mLabel, userID, userLocation) {
    const userDocRef = doc(db, "Companies", userLocation[0], "Users", userID);
    const officeDocRef = doc(db, "Companies", userLocation[0], "States", userLocation[1], "Regions", userLocation[2], "Offices", userLocation[3]);
    const userSnap = await getDoc(userDocRef);
    const officeSnap = await getDoc(officeDocRef);
    await updateDoc(officeDocRef, {
        Managers: [...officeSnap.data().Managers, {label:mLabel, value:userID}],
    });
    await updateDoc(userDocRef, {
        myOffices: [...userSnap.data().myOffices, userLocation[3]]
    });
}