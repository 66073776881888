import { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import { PuffLoader } from 'react-spinners';
import { onSnapshot, doc, deleteDoc, collection, updateDoc, getDocs, getDoc } from "firebase/firestore";
import { Toolbar, 
  Tooltip, 
  IconButton, 
  Typography, 
  OutlinedInput, 
  TextField,
  Card,
  Input,
  InputAdornment,
  Checkbox,
  Button } from '@mui/material';
// component
import './MassEmail.css';
import { MassEmailHTML } from './MassEmailHTML';
import Iconify from '../../../components/iconify';
import { db, sendEmail } from '../../../firebase';

// ----------------------------------------------------------------------

export default function MassEmailModal(props) {
  const [loading, setLoading] = useState(false);
  const [sendCheck1, setSendCheck1] = useState(false);
  const [sendCheck2, setSendCheck2] = useState(false);
  const [sendCheck3, setSendCheck3] = useState(false);
  const [sendCheck4, setSendCheck4] = useState(false);
  const [sendCheck5, setSendCheck5] = useState(false);
  const [description, setDescription] = useState('');
  const [message, setMessage] = useState('');

  function openMailClient(emails) {
  if(emails && emails.length > 0) {
      const bcc = emails.join(',');
      window.open(`mailto:?bcc=${bcc}`);
    } else {
      console.log("No emails provided");
    }
  }


  async function sendMass() {
    setLoading(true);
    setSendCheck1(false);
    setSendCheck2(false);
    setSendCheck3(false);
    setSendCheck4(false);
    setSendCheck5(false);
    setDescription('');
    setMessage('');

    const finalEmailList = [];
    props.currList.forEach((user) => {
      if (sendCheck1 && user[1].location.length === 1 && user[1].myOffices === undefined) { // admin/viewer
        finalEmailList.push(user[1].email);
      } else if (sendCheck2 && user[1].location.length === 3 && user[1].myOffices === undefined) { // regional
        finalEmailList.push(user[1].email);
      } else if (sendCheck3 && user[1].myOffices !== undefined) { // office
        finalEmailList.push(user[1].email);
      } else if (sendCheck4 && user[1].location.length === 4 && user[1].myOffices === undefined) { // mentor
        finalEmailList.push(user[1].email);
      }
    });

    if (sendCheck5) { // mentees
      const companySnap = await getDoc(doc(db, 'Companies', props.parent));
      const mCache = companySnap.data().menteeCache;
      mCache.forEach((mentee) => {
        finalEmailList.push(mentee.email);
      });
    }

    // const testEmails = ['elbowfun@gmail.com', 'samjst02@gmail.com', 'admin@strongmentorship.com']

    // add craig@strongrealtor.com to the final email list if it's not already there
    if (finalEmailList.indexOf('craig@strongrealtor.com') === -1) {
      finalEmailList.push('craig@strongrealtor.com');
    }

    if (finalEmailList.indexOf('sam@sam.com') !== -1) {
      finalEmailList.splice(finalEmailList.indexOf('sam@sam.com'), 1);
    }
    // console.log(testEmails)
    // console.log(finalEmailList);
    openMailClient(finalEmailList);
    setLoading(false);
    props.finished();

    // for sending the email within app
    // const emailBody = MassEmailHTML(message);
    // await sendEmail({ 
    //     fromEmail: '"StrongMentorship" <info@strongmentorship.com>',
    //     bccEmail: finalEmailList,
    //     subject: description,
    //     emailHTML: emailBody,
    // }).then(() => {
    //   setLoading(false);
    //   props.finished();
    // });
    // successToast();
  }
  return (
    <Card className='MassEmailModal'>
      <div className='CloseModal'>
        <CloseIcon onClick={() => props.closeModal()} />
      </div>
      <h3>Send Mass Email To:</h3>
      {/* Make 3 checkboxes */}
      <div style={{display:"flex", alignItems:"center", justifyContent:"center", marginTop:"-20px"}}>
        <div style={{margin:"10px"}}>
          Viewers <br /> <Checkbox onChange={() => setSendCheck1(!sendCheck1)} checked={sendCheck1}/>
        </div>
        <div style={{margin:"10px"}}>
          Regional <br /> <Checkbox onChange={() => setSendCheck2(!sendCheck2)} checked={sendCheck2}/>
        </div>
        <div style={{margin:"10px"}}>
          Office <br /> <Checkbox onChange={() => setSendCheck3(!sendCheck3)} checked={sendCheck3}/>
        </div>
        <div style={{margin:"10px"}}>
          Mentors <br /> <Checkbox onChange={() => setSendCheck4(!sendCheck4)} checked={sendCheck4}/>
        </div>
        <div style={{margin:"10px"}}>
          Mentees <br /> <Checkbox onChange={() => setSendCheck5(!sendCheck5)} checked={sendCheck5}/>
        </div>
      </div>
      <div style={{marginTop:"15px"}} />
      <Button variant='contained' 
      style={{width:"90px", height:"38px"}} 
      onClick={() => sendMass()}>
      {loading ?  <PuffLoader color="#ffffff" size="40px"/> :
      "Send"
      }</Button>
    </Card>
  );
}
