import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { MoonLoader } from 'react-spinners';
import CloseIcon from '@mui/icons-material/Close';
import Select from 'react-select';
import './AddModal.css';
// @mui
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
} from '@chakra-ui/react';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  TextField,
} from '@mui/material';
// firebase
import { onSnapshot, doc, setDoc, getDocs, addDoc, getDoc, collection, updateDoc } from "firebase/firestore";
import { db } from '../firebase';
import { UserAuth } from '../contexts/AuthContext';
import inviteUser from '../intake/inviteUser';
import appointOfficeManager from '../intake/appointOfficeManager';
// components
import Iconify from '../components/iconify';

const ORDER = ['Region', 'Office', 'Mentor', 'Mentee']

export default function ViewManagers(props) {
    const [loading, setLoading] = useState(false);
    const [officeName, setOfficeName] = useState(false);
    const [officeManagerList, setOfficeManagerList] = useState([]);

    async function getOfficeManagers() {
        console.log(props.location)
        const officeDoc = await getDoc(doc(db, "Companies", props.location[0], "States", props.location[1], "Regions", props.location[2], "Offices", props.location[3]));
        const mList = officeDoc.data().Managers;
        await Promise.all(
            mList.map(async (manager) => {
                const managerDoc = await getDoc(doc(db, "Companies", props.location[0], "Users", manager.value));
                manager.hasLoggedIn = managerDoc.data().hasLoggedIn;
            })
        );
        setOfficeManagerList(mList);
        setOfficeName(officeDoc.data().Label);
    }

    useEffect(() => {
        getOfficeManagers();
    }, [])

    async function removeManager(managerToRemove) {
        const newManagerList = officeManagerList.filter((manager) => manager.value !== managerToRemove.value);
        setOfficeManagerList(newManagerList);
        const officeRef = doc(db, "Companies", props.location[0], "States", props.location[1], "Regions", props.location[2], "Offices", props.location[3])
        const managerRef = doc(db, "Companies", props.location[0], "Users", managerToRemove.value)
        await updateDoc(officeRef, {
            Managers: newManagerList
        });

        const managerSnap = await getDoc(managerRef);
        await updateDoc(managerRef, {
            myOffices: filter(managerSnap.data().myOffices, (office) => office !== props.location[3])
        })
    }

    return (
        <Card className='AddNewModal'>
            {!loading && (
                <div>
                    <>
                        Managers for {officeName}
                        <CloseIcon className='CloseWindow' onClick={() => props.closeModal()}/>
                    </>
                    <div className='OManagerList'>
                        {officeManagerList.map((manager) => (
                            <div className='OManager' style={manager.hasLoggedIn ? {color:"white"} : {color:"yellow"}}>
                                {manager.label}
                                <div style={{marginLeft:"auto"}}>
                                    <button style={{color:"red"}} onClick={() => removeManager(manager)}>Remove</button>
                                </div>
                            </div>
                        ))}
                        {officeManagerList.length === 0 && (
                            <div style={{marginTop:"120px"}}>

                            No office managers yet. Add one with the button below.
                            </div>
                        )}
                    </div>
                    <Button variant="contained" 
                        style={{marginLeft:"auto"}} 
                        onClick={() => props.addManager()}
                        startIcon={<Iconify icon="eva:plus-fill" 
                        />}>
                        Add New Manager
                    </Button>
                </div>
            )}
            <div>
                {loading && (
                    <>
                        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <MoonLoader color="#2065D1"/>
                        </div>
                        <br />
                        Please wait.
                    </>
                )}
            </div>
        </Card>
    );
}
