import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { MoonLoader } from 'react-spinners';
import CloseIcon from '@mui/icons-material/Close';
import Select from 'react-select';
import './AddModal.css';
// @mui
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
} from '@chakra-ui/react';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  TextField,
} from '@mui/material';
// firebase
import { onSnapshot, doc, setDoc, getDocs, addDoc, getDoc, collection, updateDoc } from "firebase/firestore";
import { db } from '../firebase';
import { UserAuth } from '../contexts/AuthContext';
import inviteUser from '../intake/inviteUser';
import appointOfficeManager from '../intake/appointOfficeManager';
// components
import Iconify from '../components/iconify';

const ORDER = ['Region', 'Office', 'Mentor', 'Mentee']

export default function EditName(props) {
    const [loading, setLoading] = useState(false);
    const [label, setLabel] = useState('');

    useEffect(() => {
        if (props.menuOpenOn.length > 0 && (props.current < 3 && props.current > 0)) {
            setLabel(props.menuOpenOn[1])
        }
        console.log(props.menuOpenOn)
    }, [])

    async function handleSave() {
        setLoading(true);
        if (props.current === 1) {
            await updateDoc(doc(db, "Companies", props.location[0], "States", props.location[1], "Regions", props.location[2]), {
                Label: label
            }).then(() =>{
                props.closeModal()
            });
        } else if (props.current === 2) {
            await updateDoc(doc(db, "Companies", props.location[0], "States", props.location[1], "Regions", props.location[2], "Offices", props.location[3]), {
                Label: label
            }).then(() =>{
                props.closeModal()
            });
        }
    }

    return (
        <Card className='AddNewModal'>
            <div>
                {!loading && (
                    <>
                        Change Label
                        <CloseIcon className='CloseWindow' onClick={() => props.closeModal()}/>
                    </>
                )}
            </div>

            {!loading ?
            <>
                <TextField name="Label"
                    label= {`Label`}
                    value={label}
                    onChange={e=>setLabel(e.target.value)}
                    style={{width:"300px"}}
                    />
                <div style={{marginTop:"15px"}} />
                <Button onClick={() => handleSave()}>Save</Button>
            </>
        
            :
            <>
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <MoonLoader color="#2065D1"/>
                    </div>
                    <br />
                    Saving data. Please wait.
                </>
            
            }
        </Card>
    );
}
