import { useState, useEffect } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { db } from '../../../firebase';
// mocks_

// ----------------------------------------------------------------------
import { UserAuth } from '../../../contexts/AuthContext';

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
  },
  {
    label: 'Settings',
    icon: 'eva:settings-2-fill',
  },
];

// ----------------------------------------------------------------------
const PARENT = window.location.host.split('.')[0];

export default function AccountPopover() {
  const [open, setOpen] = useState(null);

  const navigate = useNavigate();
  const { user, logout } = UserAuth();
  const [account, setAccount] = useState({});

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    user.getIdTokenResult().then((idTokenResult) => {
      const name = `${idTokenResult.claims.firstName} ${idTokenResult.claims.lastName}`;
      setAccount({
          displayName: name,
          role: idTokenResult.claims.accountType,
          avatarName: name[0],
          email: user.email,
          photoURL: '/assets/images/avatars/avatar_default.jpg',
        })
    });
  }, []);

  const handleLogout = async () => {
        try {
        await updateDoc(doc(db, "Companies", PARENT, "Users", user.uid), {
          isLoggedIn: false,
        });
        await logout();
        navigate('/');
        console.log('You are logged out')
        } catch (e) {
        console.log(e.message);
        }
  };

  if (account.displayName) {
    return (
      <>
        <IconButton
          onClick={handleOpen}
          sx={{
            p: 0,
            ...(open && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
              },
            }),
          }}
        >
          <Avatar alt="photoURL">{account.avatarName}</Avatar>
        </IconButton>

        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 0,
              mt: 1.5,
              ml: 0.75,
              width: 180,
              '& .MuiMenuItem-root': {
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <Box sx={{ my: 1.5, px: 2.5 }}>
            <Typography variant="subtitle2" noWrap>
              {account.displayName}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {account.email}
            </Typography>
          </Box>

          <Divider sx={{ borderStyle: 'dashed' }} />

          <Stack sx={{ p: 1 }}>
            {MENU_OPTIONS.map((option) => (
              <MenuItem key={option.label} onClick={handleClose}>
                {option.label}
              </MenuItem>
            ))}
          </Stack>

          <Divider sx={{ borderStyle: 'dashed' }} />

          <MenuItem onClick={() => handleLogout()} sx={{ m: 1 }}>
            Logout
          </MenuItem>
        </Popover>
      </>
    );
  }
}
