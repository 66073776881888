import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { MoonLoader } from 'react-spinners';
import CloseIcon from '@mui/icons-material/Close';
import Select from 'react-select';
import './AddModal.css';
// @mui
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
} from '@chakra-ui/react';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  TextField,
} from '@mui/material';
// firebase
import { onSnapshot, doc, setDoc, getDocs, addDoc, getDoc, collection, updateDoc } from "firebase/firestore";
import { ToastContainer, toast } from 'react-toastify';
import { db } from '../firebase';
import { UserAuth } from '../contexts/AuthContext';
import inviteUser from '../intake/inviteUser';
import appointOfficeManager from '../intake/appointOfficeManager';
// components
import Iconify from '../components/iconify';

const ORDER = ['Region', 'Office', 'Mentor', 'Mentee']

export default function AddManager(props) {
    const [loading, setLoading] = useState(false);
    const [isOffice, setIsOffice] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [selectedManagerLabel, setSelectedManagerLabel] = useState('');
    const [selectedManagerID, setSelectedManagerID] = useState('');
    const [officeManagerList, setOfficeManagerList] = useState([]);
    // addState for office manager: 1 = create manager, 2 = add existing manager
    const [addState, setAddState] = useState(0);
    const [email, setEmail] = useState('');
    const { createUser, logout } = UserAuth();

    async function addManager() {
        setLoading(true);
        if (!isOffice || addState === 1) {
            await inviteUser(firstName, lastName, email, props.role, props.company, props.location).catch(error => {
                toast.error("A user with this email already exists in the system", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
        }
        else if (addState === 2) {
            console.log("Appointing office manager");
            console.log(props.location);
            await appointOfficeManager(selectedManagerLabel, selectedManagerID, props.location)
        }
        props.closeModal();
    }

    function handleSelectManager(data) {
        setSelectedManagerID(data.value);
        setSelectedManagerLabel(data.label);
    }

    async function getOfficeManagers() {
        const officeSnap = await getDoc(doc(db, "Companies", props.location[0], "States", props.location[1], "Regions", props.location[2], "Offices", props.location[3]));
        await getDocs(collection(db, "Companies", props.location[0], "States", props.location[1], "Regions", props.location[2], "OfficeManagers")).then((querySnapshot) => {
            const tempList = [];
            querySnapshot.forEach((doc) => {
                if (!officeSnap.data().Managers.map((x) => x.value).includes(doc.id)) {
                    const newEntry = {label: doc.data().ManagerLabel, value: doc.id};
                    tempList.push(newEntry);
                }
            });
            setOfficeManagerList(tempList);
        })
    }

    useEffect(() => {
        if (props.location.length === 4) {
            setIsOffice(true);
            getOfficeManagers();
        }
    })

    return (
        <>
            <Card className='AddNewModal'>
                <div>
                    {!loading && (
                        <>
                            Add {isOffice ? "Office": "Regional"} Manager
                            <CloseIcon className='CloseWindow' onClick={() => props.closeModal()}/>
                        </>
                    )}
                </div>

                {!loading && isOffice && (addState !== 0) && (
                    <div style={{display:"flex", marginTop:"-30px"}}>
                        <Button
                                style={{marginLeft:"0"}} 
                                onClick={() => setAddState(0)}>
                            Back
                        </Button>
                    </div>
                )}

                <br />
                
                {!loading && (!isOffice || addState === 1) && (
                    <>
                        <TextField name="firstName"
                            label= {`First Name`}
                            onChange={e=>setFirstName(e.target.value)}
                            style={{width:"300px"}}
                            />
                        <div style={{marginTop:"15px"}} />
                        <TextField name="lastName"
                            label= {`Last Name`}
                            onChange={e=>setLastName(e.target.value)}
                            style={{width:"300px"}}
                            />
                        <div style={{marginTop:"15px"}} />
                        <TextField name="email"
                            label= {`Email`}
                            onChange={e=>setEmail(e.target.value)}
                            style={{width:"300px"}}
                            />
                    </>
                )}
                
                {!loading && isOffice && (addState === 2) && (
                    <div style={{width:"300px"}}>
                    <div style={{ position: "relative" }}>
                            <Select 
                                options={officeManagerList}
                                onInputChange={(e) => setSearchInput(e)}
                                onChange={(e) => handleSelectManager(e)}
                                menuIsOpen={searchInput.length > 0}
                                placeholder="Search for a manager"
                                styles={{
                                    input: (base, state) => ({
                                        ...base,
                                        color:"white"
                                    }),
                                    singleValue: (base, state) => ({
                                        ...base,
                                        color:"white"
                                    }),
                                    menu: (base, state) => ({
                                    ...base,

                                    position: "absolute important",
                                    backgroundColor:"#222222",
                                    fontColor:"#FFFFFF"
                                    }),
                                    control: (base, state) => ({
                                    ...base,

                                    backgroundColor:"#222222",
                                    fontColor:"#FFFFFF"
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isSelected ? '#444444' : '#222222',
                                        color: state.isSelected ? '#222222' : '#FFFFFF',
                                    }),
                                }}
                            />
                        </div>
                        <div style={{marginTop:"30px"}} />
                        <Button variant="contained" 
                            style={{marginLeft:"auto"}} 
                            onClick={() => addManager()}
                            startIcon={<Iconify icon="eva:plus-fill" 
                            />}>
                        Add
                    </Button>
                    </div>
                )}

                {loading && (
                    <>
                        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <MoonLoader color="#2065D1"/>
                        </div>
                        <br />
                        Inviting new user. Please wait.
                    </>
                )}

                <div style={{marginTop:"20px"}} />

                {!loading && (!isOffice || addState === 1) && (
                    <Button variant="contained" 
                            style={{marginLeft:"auto"}} 
                            onClick={() => addManager()}
                            startIcon={<Iconify icon="eva:plus-fill" 
                            />}>
                        Add
                    </Button>
                )}

                {!loading && isOffice && (addState === 0) && (
                    <div style={{marginBottom:"40px"}}>
                        <Button variant="contained" 
                                style={{marginLeft:"auto"}} 
                                onClick={() => setAddState(1)}
                                startIcon={<Iconify icon="eva:plus-fill" 
                                />}>
                            Add New Manager
                        </Button>
                        <div style={{marginTop:"30px", width:"200px"}}/>
                        <Button variant="contained" 
                                style={{marginLeft:"auto"}} 
                                onClick={() => setAddState(2)}
                                startIcon={<Iconify icon="eva:plus-fill" 
                                />}>
                            Appoint Existing
                        </Button>
                    </div>
                )}
            </Card>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    );
}
