import { useEffect, useState } from 'react';
import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
// idle detection
import { UserAuth } from './contexts/AuthContext';
import { db } from './firebase';

// ----------------------------------------------------------------------
const PARENT = window.location.host.split('.')[0];
const INACTIVITY_THRESHOLD = 10 * 60 * 1000;

export default function IdleChecker({ children }) {
  const { user, logout } = UserAuth();
  const [lastTime, setLastTime] = useState(null);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
    await updateDoc(doc(db, "Companies", PARENT, "Users", user.uid), {
      isLoggedIn: false,
    });
    await logout();
    navigate('/');
    console.log('You are logged out')
    } catch (e) {
    console.log(e.message);
    }
  };

  const updateLastActivity = async (user, force) => {
    const UPDATE_INTERVAL = 1000 * 60 * 1; // 1 minute
    const currentTime = Date.now();
    // console.log("Updating last activity...")
    // console.log("Current time: ", currentTime)
    // console.log("Last time: ", lastTime)

    if (force || (currentTime - lastTime >= UPDATE_INTERVAL)) {
      const newDate = Date.now();
      await updateDoc(doc(db, "Companies", PARENT, "Users", user.uid), {
          lastActivity: newDate,
      });
      setLastTime(newDate);
    }
  }

  const onAction = () => {
    updateLastActivity(user)
  }

  const onIdle = () => {
    handleLogout();
  }

  const { getRemainingTime } = useIdleTimer({
    onAction,
    onIdle,
    timeout: INACTIVITY_THRESHOLD,
    throttle: 500
  })

  useEffect(() => {
    if (user.uid !== undefined) {
      console.log("USER", user.uid);
      updateLastActivity(user, true);
      setLastTime(Date.now());
      onSnapshot(doc(db, "Companies", PARENT, "Users", user.uid), (doc) => {
        if (doc.data().isInactive && doc.data().isLoggedIn) {
          handleLogout();
        }
      }); 
    }
  }, [user]);

  return (
      <>{children}</>
  );
}
