// component
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import GridViewIcon from '@mui/icons-material/GridView';
import GroupsIcon from '@mui/icons-material/Groups';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import SvgColor from '../../../components/svg-color';
// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Stats',
    path: '/dashboard/stats',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Dashboard',
    path: '/dashboard/states',
    icon: <GridViewIcon />,
  },
  {
    title: 'Sales',
    path: '/dashboard/sales',
    icon: <AttachMoneyIcon />,
  },
  {
    title: 'Mentee Curriculum',
    path: '/dashboard/menteecurriculum',
    icon: <FormatListNumberedIcon />,
  },
  {
    title: 'Users',
    path: '/dashboard/users',
    icon: icon('ic_user'),
  },
  {
    title: 'Viewers',
    path: '/dashboard/viewers',
    icon: <RemoveRedEyeIcon />,
  },
  {
    title: 'Zoom Meetings',
    path: 'zoom',
    icon: <GroupsIcon />,
  },
  {
    title: 'Mentee Contract',
    path: '/dashboard/contract',
    icon: <HistoryEduIcon />,
  },
  {
    title: 'Help',
    path: '/dashboard/help',
    icon: <QuestionMarkIcon />,
  },
];

export default navConfig;
