import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// firebase
import { db, setCustomUserClaims } from '../../../firebase'
// components
import { UserAuth } from '../../../contexts/AuthContext';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const { createUser, logout } = UserAuth();

  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  // Logout function
  const handleLogout = async () => {
      try {
      await logout();
      navigate('/');
      console.log('You are logged out')
      } catch (e) {
      console.log(e.message);
      }
  };

  async function handleSubmit(e) {
    if (email !== "" && password === passwordConfirm && password.length >= 6) {
      setLoading(true);
      await createUser(email, password).then(async(userdata) => {
          const userid = userdata.user.uid;
          setCustomUserClaims({ 
              uid: userid,
              accountType: "Admin",
              parentName: "Compass",
              firstName: firstname,
              lastName: lastname,
          });
          handleLogout();
          navigate('/');
      });
      setLoading(false);
    }
  }

  const handleClick = () => {
    if (email !== "" && password !== "") {
      handleSubmit();
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="firstname"
                   label="First Name"
                   onChange={e => setFirstName(e.target.value)} />

        <TextField name="lastname"
                   label="Last Name"
                   onChange={e => setLastName(e.target.value)} />
        
        <TextField name="email"
                   label="Email address"
                   onChange={e => setEmail(e.target.value)} />

        <TextField
          name="password"
          label="Password"
          onChange={e => setPassword(e.target.value)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          name="password"
          label="Confirm Password"
          onChange={e => setPasswordConfirm(e.target.value)}
          type={showPasswordConfirm ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPasswordConfirm)} edge="end">
                  <Iconify icon={showPasswordConfirm ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <br />

      {email !== "" && password !== "" ?
      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Register
      </LoadingButton>
      :
      <LoadingButton fullWidth size="large" type="submit" variant="contained" disabled>
        Register
      </LoadingButton>
      }
      <div style={{marginTop:"10px", color:"red"}}>
        {error}
      </div>
    </>
  );
}
