import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
// @mui
import { Container, Stack, Typography, ToggleButton, ToggleButtonGroup, Button, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import './MenteeCurriculum.css'
import { getDoc, doc, setDoc, updateDoc, onSnapshot, deleteDoc } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import { db, storage } from '../firebase';
import './Viewers.css'

// ----------------------------------------------------------------------
const PARENT = window.location.host.split('.')[0];

export default function MenteeContract() {
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [helpMSG, setHelpMSG] = useState('');

  async function downloadContract() {
    const fileRef = ref(storage, '/compass/Final_Agreement__Mentorship_Program_-_Addendum_.pdf')
    await getDownloadURL(fileRef).then(url => window.open(url))
  }

  return (
    <>
      <Helmet>
        <title>Mentee Contract</title>
      </Helmet>

      <div style={{textAlign:"center"}}>
        <div>
          <h1>Step 1</h1>
          Download the contract here:
          <Button onClick={() => downloadContract()}>
            Download
          </Button>
        </div>
        <div>
          <h1>Step 2</h1>
          Fill in the Mentor and Mentee's name
        </div>
        <div>
          <h1>Step 3</h1>
          Docusign to Mentor, Mentee, Craig Strong and Manager
        </div>
        <div>
          <h1>Step 4</h1>
          Upload when creating the Mentee on the Dashboard Page
        </div>
      </div>
    </>
  );
}
