import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import LandingPage from './pages/LandingPage';

// ----------------------------------------------------------------------

export default function NoAffRouter() {
  const routes = useRoutes([
    {
      path: '/',
      element: <LandingPage />,
    },
  ]);

  return routes;
}
