import PropTypes from 'prop-types';
import { set, sub } from 'date-fns';
import { noCase } from 'change-case';
import { faker } from '@faker-js/faker';
import { getDoc, doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import PaidIcon from '@mui/icons-material/Paid';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Popover,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
import SellIcon from '@mui/icons-material/Sell';
import AddHomeIcon from '@mui/icons-material/AddHome';
// utils
import { fToNow } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import { UserAuth } from '../../../contexts/AuthContext';
import { db } from '../../../firebase';

const PARENT = window.location.host.split('.')[0];

// ----------------------------------------------------------------------

function toDateTime(secs) {
    const t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(secs);
    return t.toLocaleDateString();
}

function formatNumberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function NotificationsPopover() {
  const [viewedNotifs, setViewedNotifs] = useState([]);
  const [unviewedNotifs, setUnviewedNotifs] = useState([]);
  const [allNotifs, setAllNotifs] = useState([]);
  const [totalUnRead, setTotalUnRead] = useState(0);
  const { user } = UserAuth();
  const navigate = useNavigate();


  async function getNotifications(uid) {
    const userDoc = await getDoc(doc(db, 'Companies', PARENT, 'Users', uid));
    const vn = [];
    const uvn = [];

    userDoc.data().Notifications.forEach(async (entry, key) => {
      const menteeDoc = await getDoc(doc(db, 'Companies', entry.location[0], 'States', entry.location[1], 'Regions', entry.location[2], 'Offices', entry.location[3], 'Mentors', entry.location[4], "Mentees", entry.mentee));
      const mentorDoc = await getDoc(doc(db, 'Companies', entry.location[0], 'States', entry.location[1], 'Regions', entry.location[2], 'Offices', entry.location[3], 'Mentors', entry.location[4]));
      const officeDoc = await getDoc(doc(db, 'Companies', entry.location[0], 'States', entry.location[1], 'Regions', entry.location[2], 'Offices', entry.location[3]));
      const regionDoc = await getDoc(doc(db, 'Companies', entry.location[0], 'States', entry.location[1], 'Regions', entry.location[2]));
      const menteeName = `${menteeDoc.data().mFirstName} ${menteeDoc.data().mLastName}`
      const mentorName = `${mentorDoc.data().firstName} ${mentorDoc.data().lastName}`
      const officeName = `${officeDoc.data().Label}`
      const regionName = `${regionDoc.data().Label}`

      const newNotif = {};

      newNotif.isUnRead = !entry.viewed;
      newNotif.location= entry.location;
      newNotif.id = key;
      newNotif.title = (entry.type === 'openSale' ? 'Escrow Opened' : 'Escrow Closed');
      newNotif.description = `${menteeName} (mentee) and ${mentorName} (mentor) from the ${officeName} office in ${regionName}, ${entry.location[1]}`;
      newNotif.createdAt = toDateTime(entry.time.seconds);
      newNotif.value = formatNumberWithCommas(entry.amount);
      newNotif.avatar = null;
      newNotif.type = (entry.type === 'openSale' ? 'open_escrow' : 'close_escrow');

      if (entry.viewed) {
        vn.push(newNotif);
      } else {
        uvn.push(newNotif);
      }
      
    });

    // setAllNotifs(userDoc.data().Notifications);
    setUnviewedNotifs(uvn);
    setViewedNotifs(vn);
  }

  async function countUnRead(uid) {
    const userDoc = await getDoc(doc(db, 'Companies', PARENT, 'Users', uid));
    const tempUnread = [0]
    for (let i = 0; i < userDoc.data().Notifications.length; i+=1) {
      if (!userDoc.data().Notifications[i].viewed) {
        tempUnread[0] += 1;
      }
    }
    setTotalUnRead(tempUnread[0]);
  }

  useEffect(() => {
    getNotifications(user.uid);
    countUnRead(user.uid);
    const userData = onSnapshot(doc(db, 'Companies', PARENT, 'Users', user.uid), (doc) => {
      getNotifications(user.uid);
      countUnRead(user.uid);
    })
  }, []);

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
    setTotalUnRead(0);
  };

  async function readAll(uid) {
    const userDoc = await getDoc(doc(db, 'Companies', PARENT, 'Users', uid));
    const finalNotifs = userDoc.data().Notifications;
    for (let i = 0; i < finalNotifs.length; i+=1) {
      finalNotifs[i].viewed = true;
    }

    await updateDoc(doc(db, 'Companies', PARENT, 'Users', uid), {
      Notifications: finalNotifs,
    });
  }

  const handleClose = () => {
    setOpen(null);
    readAll(user.uid);
  };

  // const handleMarkAllAsRead = () => {
  //   setNotifications(
  //     notifications.map((notification) => ({
  //       ...notification,
  //       isUnRead: false,
  //     }))
  //   );
  // };

  async function deleteNotification(item) {
    const userDoc = await getDoc(doc(db, 'Companies', PARENT, 'Users', user.uid));
    const finalNotifs = userDoc.data().Notifications;
    finalNotifs.splice(item.id, 1);
    await updateDoc(doc(db, 'Companies', PARENT, 'Users', user.uid), {
      Notifications: finalNotifs,
    });
    getNotifications(user.uid);
    countUnRead(user.uid);
  }

  function openNotification(item) {
    navigate(`/dashboard/states/${item.location[1]}/regions/${item.location[2]}/offices/${item.location[3]}/mentors/${item.location[4]}/mentees`);
    handleClose();
    deleteNotification(item);
  }

  return (
    <>
      <IconButton color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 360,
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread notifications.
            </Typography>
          </Box>

          {/* {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )} */}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          {unviewedNotifs.length > 0 && (<List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                New
              </ListSubheader>
            }
          >
            {unviewedNotifs.map((notif, i) => (
              <NotificationItem key={notif.id} notification={notif} onClick={() => openNotification(notif)}/>
            ))}
          </List>)}

          {viewedNotifs.length > 0 && (<List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Old
              </ListSubheader>
            }
          >
            {viewedNotifs.map((notif, i) => (
              <div role="presentation" onClick={() => openNotification(notif)}>
              <NotificationItem key={notif.id} notification={notif} onClick={() => openNotification(notif)}/>
              </div>
            ))}
          </List>)}
        </Scrollbar>
      </Popover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.string,
    value: PropTypes.string,
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function NotificationItem({ notification }) {
  const { avatar, title } = renderContent(notification);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <PaidIcon style={{fontSize:"17px"}}/>
            &nbsp;{notification.value}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {notification.createdAt}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {notification.description}
      </Typography>
    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_package.svg" />,
      title,
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_shipping.svg" />,
      title,
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_mail.svg" />,
      title,
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_chat.svg" />,
      title,
    };
  }
  if (notification.type === 'close_escrow') {
    return {
      avatar: <SellIcon style={{color:"green"}}/>,
      title,
    };
  }
  if (notification.type === 'open_escrow') {
    return {
      avatar: <AddHomeIcon style={{color:"yellow"}}/>,
      title,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
