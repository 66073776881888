import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
import { UserAuth } from '../../../contexts/AuthContext';
//
import navConfig from './config';
import cslogo from './csalone.png';
import './NavStuff.css';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const navConfigNotAdmin = navConfig.filter((item) => item.title !== 'Viewers' && item.title !== 'Users' && item.title !== 'Sales');

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha("#000000", 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');
  const { user } = UserAuth();

  const [account, setAccount] = useState({});
  const [hideLogo, setHideLogo] = useState(false);
  const [aType, setAType] = useState('');

  // https://samstrong.netlify.app/
  function handleLinkClick() {
    window.location.href = 'https://samstrong.netlify.app';
  };

  useEffect(() => {
    user.getIdTokenResult().then((idTokenResult) => {
      const name = `${idTokenResult.claims.firstName} ${idTokenResult.claims.lastName}`;
      setAType(idTokenResult.claims.accountType);
      setAccount({
          displayName: name,
          avatarName: name[0],
          role: idTokenResult.claims.accountType,
          email: user.email,
          photoURL: '/assets/images/avatars/avatar_default.jpg',
        })
    });

    if (openNav) {
      onCloseNav();
    }

    // check on every window resize
    window.addEventListener('resize', () => {
      if (window.innerHeight < 800) {
        setHideLogo(true);
      } else {
        setHideLogo(false);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none">
          <StyledAccount>
            <Avatar alt="photoURL">{account.avatarName}</Avatar>

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {account.displayName}
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {account.role}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <NavSection data={aType === "Admin" ? navConfig : navConfigNotAdmin} />
      <div style={{marginTop:"300px"}}/>
      {!hideLogo && (<div style={{position:"absolute", bottom:"20px", left:"13%"}}>
        <div style={{textAlign:"center"}}>
          <div style={{display:"flex"}}>
            <img src={cslogo} alt="cslogo" style={{width:"60px", height:"52px", margin:"auto"}}/>
          </div>
             StrongMentorship
        </div>
        <hr />
        <div style={{fontSize:"12px", color:"grey", display:"flex"}}>© 2023 Software by&nbsp;
          <a href='https://strongs20.github.io/sam-portfolio' target="_blank" rel="noreferrer" className='MeLink' style={{color:"lightblue"}}>Samuel Strong</a>
        </div>
      </div>)}

    </Scrollbar>
  );

  if (account.displayName) {
    return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
  }
}
