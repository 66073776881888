import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { MoonLoader } from 'react-spinners';
import CloseIcon from '@mui/icons-material/Close';
import GetAppIcon from '@mui/icons-material/GetApp';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AddIcon from '@mui/icons-material/Add';
import cryptoRandomString from 'crypto-random-string';
import '../AddModal.css';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Box,
  Popover,
  TextField,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// firebase
import { onSnapshot, doc, updateDoc, getDocs, addDoc, getDoc, collection, setDoc } from "firebase/firestore";
import { ref, listAll, getDownloadURL, uploadBytes, deleteObject } from 'firebase/storage';
import { db, storage } from '../../firebase';
import { UserAuth } from '../../contexts/AuthContext';
import inviteUser from '../../intake/inviteUser';
// components
import Iconify from '../../components/iconify';

function MoneyTextField(props) {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (props.value !== "") {
        setValue(props.value);
    }
  }, [])

  const handleChange = (event) => {
    const inputValue = event.target.value;
    const onlyNums = inputValue.replace(/[^0-9]/g, '');
    const formattedValue = formatNumberWithCommas(onlyNums);
    setValue(formattedValue);
    const noCommas = formattedValue.replace(/,/g, '');
    props.onChange(noCommas)
  };

  const formatNumberWithCommas = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <AttachMoneyIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
        <TextField id="input-with-sx" label={props.placeholder} variant="standard" onChange={handleChange} value={value}/>
    </Box>
  );
}

function formatNumberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export default function EscrowReport(props) {
    const [loading, setLoading] = useState(false);
    const [escrowList, setEscrowList] = useState([]);

    const [openEscrow, setOpenEscrow] = useState(false);
    const [openName, setOpenName] = useState('');
    const [openPrice, setOpenPrice] = useState(0);
    const [openDate, setOpenDate] = useState('');

    const [editEscrow, setEditEscrow] = useState(false);
    const [editEscrowKey, setEditEscrowKey] = useState(false);

    const [closeEscrow, setCloseEscrow] = useState(0);
    const [showCloseEscrow, setShowCloseEscrow] = useState(false);
    const [soldPrice, setSoldPrice] = useState(0);
    const [finalCommission, setFinalCommission] = useState(0);
    const [closeDate, setCloseDate] = useState('');

    const [confirmDelete, setConfirmDelete] = useState(false);
    const [deleteKey, setDeleteKey] = useState(false);

    async function retrieveMentee() {
        onSnapshot(doc(db, "Companies", props.location[0], "States", props.location[1], "Regions", props.location[2], "Offices", props.location[3], "Mentors", props.location[4], "Mentees", props.mentee), (doc) => {
            if (doc.data().mEscrow && doc.data().mEscrow.length > 0) {
                setEscrowList(doc.data().mEscrow);
            }
        });
    }

    async function saveOpenEscrow() {
        setLoading(true);
        setOpenEscrow(false);
        const menteeRef = doc(db, "Companies", props.location[0], "States", props.location[1], "Regions", props.location[2], "Offices", props.location[3], "Mentors", props.location[4], "Mentees", props.mentee);
        const menteeSnap = await getDoc(menteeRef);
        
        if (menteeSnap.data().mEscrow) {
            const menteeEscrow = menteeSnap.data().mEscrow;
            menteeEscrow.push({
                name: openName, 
                price: Math.round(openPrice), 
                oDate: openDate, 
                cDate: "N/A", 
                cPrice: 0,
                cCommission: 0,
                closed: false,
            });
            await updateDoc(menteeRef, {mEscrow: menteeEscrow});
        } else {
            const menteeEscrow = [];
            menteeEscrow.push({
                name: openName, 
                price: Math.round(openPrice), 
                oDate: openDate, 
                cDate: "N/A", 
                cPrice: 0,
                cCommission: 0,
                closed: false,
            });
            await updateDoc(menteeRef, {mEscrow: menteeEscrow});
        }

        const companyRef = doc(db, "Companies", props.location[0]);
        const companySnap = await getDoc(companyRef);
        await updateDoc(companyRef, {
            openSales: [...companySnap.data().openSales, {type:"openSale", amount: Math.round(openPrice), date: openDate, mentee: props.mentee, location: props.location, time: new Date()}],
        });

        setOpenDate('');
        setOpenPrice(0);
        setOpenName('');
        setLoading(false);
    }

    useEffect(() => {
        retrieveMentee(1);
        // const rand = cryptoRandomString({length: 20, type: 'alphanumeric'});
        // console.log(rand);
    }, []);

    function openCloseEscrow(key) {
        setCloseEscrow(key);
        setShowCloseEscrow(true);
    }

    function openEditEscrow(key) {
        setEditEscrowKey(key);
        setOpenDate(escrowList[key].oDate);
        setOpenPrice(formatNumberWithCommas(escrowList[key].price));
        setOpenName(escrowList[key].name);
        setEditEscrow(true);
    }

    async function saveEditEscrow() {
        const menteeRef = doc(db, "Companies", props.location[0], "States", props.location[1], "Regions", props.location[2], "Offices", props.location[3], "Mentors", props.location[4], "Mentees", props.mentee);
        const menteeEscrow = escrowList;
        menteeEscrow[editEscrowKey].name = openName;
        menteeEscrow[editEscrowKey].price = openPrice;
        menteeEscrow[editEscrowKey].oDate = openDate;
        await updateDoc(menteeRef, {mEscrow: menteeEscrow});

        setEditEscrow(false);
        setOpenDate('');
        setOpenPrice(0);
        setOpenName('');
    }

    async function saveCloseEscrow() {
        setLoading(true);
        setShowCloseEscrow(false);
        const menteeRef = doc(db, "Companies", props.location[0], "States", props.location[1], "Regions", props.location[2], "Offices", props.location[3], "Mentors", props.location[4], "Mentees", props.mentee);
        const menteeSnap = await getDoc(menteeRef)
        const menteeEscrow = escrowList;
        menteeEscrow[closeEscrow].cDate = closeDate;
        menteeEscrow[closeEscrow].cPrice = soldPrice;
        menteeEscrow[closeEscrow].cCommission = finalCommission;
        menteeEscrow[closeEscrow].closed = true;

        await updateDoc(menteeRef, {mEscrow: menteeEscrow, mSales: menteeSnap.data().mSales + Math.round(soldPrice)});

        setCloseEscrow(0);
        setCloseDate('');
        setSoldPrice(0);
        setFinalCommission(0);

        // const mentorRef = doc(db, "Companies", props.location[0], "States", props.location[1], "Regions", props.location[2], "Offices", props.location[3], "Mentors", props.location[4]);
        // const mentorSnap = await getDoc(mentorRef);
        // await updateDoc(mentorRef, {
        //     totalSales: mentorSnap.data().totalSales + Math.round(soldPrice), 
        //     totalCommission: mentorSnap.data().totalCommission + Math.round(finalCommission)
        // });

        // const officeRef = doc(db, "Companies", props.location[0], "States", props.location[1], "Regions", props.location[2], "Offices", props.location[3]);
        // const officeSnap = await getDoc(officeRef);
        // await updateDoc(officeRef, {
        //     totalSales: officeSnap.data().totalSales + Math.round(soldPrice), 
        //     totalCommission: officeSnap.data().totalCommission + Math.round(finalCommission)
        // });

        // const regionRef = doc(db, "Companies", props.location[0], "States", props.location[1], "Regions", props.location[2]);
        // const regionSnap = await getDoc(regionRef);
        // await updateDoc(regionRef, {
        //     totalSales: regionSnap.data().totalSales + Math.round(soldPrice), 
        //     totalCommission: regionSnap.data().totalCommission + Math.round(finalCommission)
        // });

        // const stateRef = doc(db, "Companies", props.location[0], "States", props.location[1]);
        // const stateSnap = await getDoc(stateRef);
        // await updateDoc(stateRef, {
        //     totalSales: stateSnap.data().totalSales + Math.round(soldPrice), 
        //     totalCommission: stateSnap.data().totalCommission + Math.round(finalCommission)
        // });

        const companyRef = doc(db, "Companies", props.location[0]);
        const companySnap = await getDoc(companyRef);
        await updateDoc(companyRef, {
            sales: [...companySnap.data().sales, {type:"closeSale", amount: soldPrice, date: closeDate, mentee: props.mentee, commission: Math.round(finalCommission), location: props.location, time: new Date(), address: menteeEscrow[closeEscrow].name}],
            totalSales: companySnap.data().totalSales + Math.round(soldPrice), 
            totalCommission: companySnap.data().totalCommission + Math.round(finalCommission)
        });
        setLoading(false);
    }

    function promptConfirmDelete(key) {
        setDeleteKey(key);
        setConfirmDelete(true);
    }

    async function actuallyDelete() {
        const menteeEscrow = [];
        if (escrowList.length >= 1) {
            for (let i = 0; i <escrowList.length; i+=1) {
                if (i !== deleteKey) {
                    menteeEscrow.push(escrowList[i])
                }
            }
        }
        const menteeRef = doc(db, "Companies", props.location[0], "States", props.location[1], "Regions", props.location[2], "Offices", props.location[3], "Mentors", props.location[4], "Mentees", props.mentee);
        await updateDoc(menteeRef, {mEscrow: menteeEscrow});
        setEscrowList(menteeEscrow);
        setDeleteKey(0);
        setConfirmDelete(false);
    }

    return (
        <div>
            <div style={{display:"flex", position:"absolute", top:"60px"}}>
                <Button variant="contained" onClick={() => setOpenEscrow(true)}><AddIcon /> &nbsp;Open Escrow</Button>
            </div>
            {showCloseEscrow && (<div className='CloseEscrowModal'>
                <div>
                    <div className="CloseWindow">
                        <CloseIcon onClick={() => setShowCloseEscrow(false)}/>
                    </div>
                </div>
                Closing for: {escrowList[closeEscrow].name}
                <br /><br />
                <MoneyTextField placeholder={"Closing price"} onChange={e => setSoldPrice(e)}/>
                <MoneyTextField placeholder={"Total commission"} onChange={e => setFinalCommission(e)}/>
                <br />
                <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                    Date Closed: &nbsp; &nbsp; <TextField variant="standard" type="date" value={closeDate} onChange={e => setCloseDate(e.target.value)}/>
                </div>
                <br />
                <Button onClick={()=> saveCloseEscrow()}>Confirm</Button>
            </div>)}

            {openEscrow && (<div className='OpenEscrowModal'>
                <div>
                    Open Escrow
                    <div className="CloseWindow">
                        <CloseIcon onClick={() => setOpenEscrow(false)}/>
                    </div>
                </div>
                <br /><br />
                <TextField variant="standard" label="Escrow Address" value={openName} onChange={e => setOpenName(e.target.value)}/>
                <MoneyTextField placeholder={"Sales price"} onChange={e => setOpenPrice(e)}/>
                <br />
                <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                    Date Opened: &nbsp; &nbsp; <TextField variant="standard" type="date" value={openDate} onChange={e => setOpenDate(e.target.value)}/>
                </div>
                <br />
                <Button onClick={()=> saveOpenEscrow()}>Confirm</Button>
            </div>)}

            {confirmDelete && (<div className='CloseEscrowModal'>
                <div>
                    Are you sure you want to delete: {escrowList[deleteKey].name}
                    <div className="CloseWindow">
                        <CloseIcon onClick={() => setConfirmDelete(false)}/>
                    </div>
                </div>
                <br /><br />
                <Button variant="contained" onClick={()=> setConfirmDelete(false)}>No</Button>
                <Button onClick={()=> actuallyDelete()}>Yes</Button>
            </div>)}

            {editEscrow && (<div className='CloseEscrowModal'>
                <div>
                    Editing: {escrowList[editEscrowKey].name}
                    <div className="CloseWindow">
                        <CloseIcon onClick={() => setEditEscrow(false)}/>
                    </div>
                </div>
                <br /><br />
                <TextField variant="standard" label="Escrow Name" value={openName} onChange={e => setOpenName(e.target.value)}/>
                <MoneyTextField placeholder={"Sales price"} value={openPrice} onChange={e => setOpenPrice(e)}/>
                <br />
                <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                    Date Opened: &nbsp; &nbsp; <TextField variant="standard" type="date" value={openDate} onChange={e => setOpenDate(e.target.value)}/>
                </div>
                <br />
                <Button onClick={()=> saveEditEscrow()}>Save</Button>
            </div>)}

            <div className='EscrowList'>
                {escrowList.length >= 1 && escrowList.map((val, key) => (
                    <div className='EscrowRow' style={val.closed ? {backgroundColor:"#40b111"} : {}}>
                        <div className='EscrowRowColumn' style={{width:"50%"}}>
                            {val.name}
                        </div>
                        <div className='EscrowRowColumn' style={{width:"17%"}}>
                            {val.closed ? <>Sold: ${formatNumberWithCommas(val.cPrice)}</>
                            : 
                            <>${formatNumberWithCommas(val.price)}</>}
                        </div>
                        <div className='EscrowRowColumn' style={val.closed ? {borderStyle:"none"} : {width:"17%"}}>
                            {val.oDate}
                            {val.closed && (<> to {val.cDate}</>)}
                        </div>
                        {!val.closed && (
                            <div style={{width:"15%", minWidth:"180px"}}>
                                <button style={{color:"green"}} onClick={() => openCloseEscrow(key)}>
                                    Close
                                </button>
                                &nbsp;
                                <button style={{color:"blue"}} onClick={() => openEditEscrow(key)}>
                                    Edit
                                </button>
                                &nbsp;
                                <button style={{color:"red"}} onClick={() => promptConfirmDelete(key)}>
                                    Cancel
                                </button>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}
