import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
// @mui
import { Container, Stack, Typography, ToggleButton, ToggleButtonGroup, Button, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import './MenteeCurriculum.css'
// components
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getDoc, doc, setDoc, updateDoc, onSnapshot, deleteDoc } from 'firebase/firestore';
import { db, sendEmail } from '../firebase';
import { UserAuth } from '../contexts/AuthContext';
import { helpEmailHTML } from './HelpEmailHTML';
import inviteUser from '../intake/inviteUser';
import './Viewers.css'

// ----------------------------------------------------------------------
const PARENT = window.location.host.split('.')[0];

const FAQ = [
    {
        title:"I couldn't add a manager/mentor. What do I do?",
        description:`When you add a mentor or manager, make sure you entered all of their details correctly.
        When you add them, allow the app to load for a few seconds; the user's account is being created, they're
        being added to the database, and a welcome email is being sent. Please wait to allow all these processes
        to finish. If it still doesn't work, please send a help request with all the details.`
    },
    {
        title:"Someone I added still hasn't received the welcome email. What do I do?",
        description:`If you added a user and they haven't received the welcome email, 
        tell them to check their spam folder. If the email was sent within 
        5 minutes ago, sometimes it can take long to send.
        If neither works, you can delete the user and re-add them.
        Or, you can send a help request`
    },
    {
        title:"How do I add an office manager?",
        description:`Click on the 'View Managers' button on the office. From there, you can either add an
        existing office manager within that region, or invite a new user. This allows multiple managers to manager
        the same office, as well as a manager to manage multiple offices.`
    },
    {
        title:"I can't delete a region/office/mentor/mentee. What do I do?",
        description:`If you can't delete the data, it's probably because there are still users or subsets
        within in that area. For example, you can't delete an office if there's mentors within that
        office. Please first delete everything within, then you may try again. If this still doesn't work,
        please send a help request.`
    },
    {
        title:"Why are some users yellow and others white?",
        description:`A user who has been invited to StrongMentorship but has not signed up yet will be denoted
        with yellow text. If they have logged in, they will be denoted with white text. If a user
        has logged in but is still appearing as yellow, please send a help request with the exact
        details.`
    },
];

export default function Help() {
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [helpMSG, setHelpMSG] = useState('');

  function successToast() {
    toast.success('Request sent! It will be addressed as soon as possible. Your patience is appreciated!', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
  }

  async function sendRequest() {
    setHelpMSG('');
    const emailBody = helpEmailHTML(userName, userEmail, helpMSG);
    await sendEmail({ 
        fromEmail: '"StrongMentorship" <info@strongmentorship.com>',
        toEmail: 'admin@strongmentorship.com',
        subject: "Help Request StrongMentorship",
        emailHTML: emailBody,
    });
    successToast();
  }
  

  return (
    <>
      <Helmet>
        <title>Help</title>
      </Helmet>

      <div style={{textAlign:"center"}}>
        <TextField placeholder ={'Name'} onChange={(e) => setUserName(e.target.value)}/>
        <div style={{marginTop:"10px"}} />
        <TextField placeholder ={'Email'} onChange={(e) => setUserEmail(e.target.value)}/>
        <div style={{marginTop:"10px"}} />
        <TextField
          id="standard-multiline-static"
          label="Description"
          multiline
          rows={8}
          value={helpMSG}
          onChange={(e) => setHelpMSG(e.target.value)}
          variant="standard"
        />
        <div style={{marginTop:"10px"}} />
        <Button variant="contained" onClick={() => sendRequest()}>Submit Help Request</Button>
        <hr style={{marginTop:"20px"}}/>
        <div style={{marginTop:"20px"}} />
        <h2>FAQ</h2>
        <div style={{marginTop:"20px"}} />
        {FAQ.map((item, index) => (
            <Accordion key={index} style={{width:"80%", margin:"auto", marginBottom:"10px"}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>{item.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        {item.description}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        ))}
      </div>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        />
    </>
  );
}
