import { Helmet } from 'react-helmet-async';
import { Container, Typography } from '@mui/material';
import { useEffect } from 'react';
import MySvg from '../landingassets/2.svg';
import ChairLoser from '../landingassets/landphoto.png';
import Btn from '../landingassets/4.svg';
import Stmt from '../landingassets/3.svg';
import Sig from '../landingassets/sig.svg';
import Stand from '../landingassets/stand.svg';
import './landing.css'

export default function LandingPage() {

  return (
    <>
      <Helmet>
        <title> Welcome to StrongMentorship </title>
      </Helmet>

      <div style={{display:"flex", height:"20px"}}>
        <t className="hide-on-small"  style={{textAlign:"right", opacity:"70%", marginLeft:"auto", padding:"10px", marginRight:"20px"}}>looking for the dashboard? <a style={{color:"#ffffff"}} href="https://compass.strongmentorship.com/">click here</a></t>
      </div>

      <img className="hide-on-small" style={{position:"absolute", left:"-1vw", top:"3%", width:"30%", zIndex:"-20"}} src={ChairLoser} alt="" />

      <div style={{display:"flex", width:"100vw", marginTop:"3%"}}>
        <img style={{margin:"auto", width:"60%", maxWidth:"700px",}} src={MySvg} alt="" />
      </div>

        <div style={{display:"flex", width:"100vw"}}>
          <Typography className='maintext' variant="h4" gutterBottom style={{textAlign:"justify",  width:"60%", maxWidth:"700px", zIndex:"1", fontSize:"21px", margin:"auto", marginTop:"5%"}}>
          From early entrepreneurial hurdles to top 1% nationwide, I've mastered the art
          of thriving in business. Now, I'm sharing the secrets that propelled me to be
          the number one agent in my market. Let's unlock business success together!
        </Typography>
      </div>

      <div style={{display:"flex"}}>
        <img className="hide-on-small" src={Sig} style={{width:"28vw", marginLeft:"auto", marginRight:"10vw", maxWidth:"600px"}} alt="" />
      </div>

      <a href='https://us05web.zoom.us/webinar/register/WN_LOoaf-oySfuZgNcruYjyZQ'
        target="_blank" rel="noopener noreferrer"
        style={{display:"flex", width:"100vw", marginTop:"5%"}}>
        <img className='sign-up-btn' style={{margin:"auto", width:"40%", minWidth:"400px", maxWidth:"700px"}} src={Btn} alt="" />
      </a>

      <div style={{display:"flex", width:"100vw", marginTop:"6%"}}>
        <img style={{margin:"auto", width:"50%",  maxWidth:"700px"}} src={Stmt} alt="" />
      </div>

      <div className='tbanner' style={{
        background: "#172d43",
        width: "80vw",
        maxWidth:"1200px",
        marginTop:"6%",
        padding: "20px",
        // Adjust the clip-path to create an angled edge
        clipPath: "polygon(0 0, 100% 0, 75% 100%, 0 100%)",
        // The third value "75% 100%" means the angle will end at 75% of the width on the bottom edge
      }}>
        <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "25px"}}>
          WHAT TO EXPECT
        </div>
        <hr style={{width:"50%", marginLeft:"5%", marginBottom:"2vw"}}/>
        <div style={{ textAlign: "left", fontSize: "24px", color: "white", marginLeft:"5vw" }}>
        <ul style={{ listStyle: "none", paddingLeft: "0", textAlign: "left", display: "inline-block", textIndent: "-20px", marginBottom:"2vw" }}>
          <li style={{ marginBottom: "10px" }}>
            <span style={{ marginRight: "10px" }}>✔</span>
            Personal mentorship from me
          </li>
          <li style={{ marginBottom: "10px" }}>
            <span style={{ marginRight: "10px" }}>✔</span>
            Access to my mentoring platform
          </li>
          <li style={{ marginBottom: "10px" }}>
            <span style={{ marginRight: "10px" }}>✔</span>
            Weekly mentor meetings
          </li>
          <li style={{ marginBottom: "10px" }}>
            <span style={{ marginRight: "10px" }}>✔</span>
            Proven strategies to build your business
          </li>
        </ul>
      </div>
      </div>

      <div style={{display:"flex"}}>
        <div style={{ textAlign: "center", fontWeight: "bold", margin:"auto", marginTop:"6%", fontSize: "45px",width:"60%", maxWidth:"700px"}}>
          ARE YOU READY TO GROW A <t className='outlined-text'>
            SIX-FIGURE INCOME &
          BE THE BEST IN THE BUSINESS?
          </t>
        </div> 
      </div>


      <a href='https://us05web.zoom.us/webinar/register/WN_LOoaf-oySfuZgNcruYjyZQ'
        target="_blank" rel="noopener noreferrer"
        style={{display:"flex", width:"100vw",  marginTop:"6%", zIndex:"9999"}}>
        <img className='sign-up-btn' style={{margin:"auto", width:"70%", minWidth:"400px", maxWidth:"800px",}} src={Btn} alt="" />
      </a>

      <div style={{height:"200px"}} />


      <div style={{
        position: 'fixed', // Fixed position to cover the viewport
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        background: 'linear-gradient(to bottom, black, black, #004A6D)',
        zIndex: -25 // Low z-index to keep it behind all other content
      }} />
    </>
  );
}
