import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDoc, updateDoc, doc } from 'firebase/firestore';
import { useSpring, animated } from 'react-spring';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { UserAuth } from '../../../contexts/AuthContext';
import Iconify from '../../../components/iconify';
import { db } from '../../../firebase';

// ----------------------------------------------------------------------
const PARENT = window.location.host.split('.')[0];

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const { signIn } = UserAuth();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);

  const {user, logout} = UserAuth();

  const handleLogout = async () => {
    try {
    await updateDoc(doc(db, "Companies", PARENT, "Users", user.uid), {
      isLoggedIn: false,
    });
    await logout();
    navigate('/');
    console.log('You are logged out')
    } catch (e) {
    console.log(e.message);
    }
  };

  async function handleSubmit() {
    setError('');
    try {
        await signIn(email, password).then(async (data) => {
            const userDoc = await getDoc(doc(db, "Companies", PARENT, "Users", data.user.uid));
            if (userDoc.data().loginCount === undefined) {
              await updateDoc(doc(db, "Companies", PARENT, "Users", data.user.uid), {
                hasLoggedIn: true,
                isLoggedIn: true,
                isInactive: false,
                loginCount: 1
              }).then(() => {
                navigate('/dashboard');
              });
            } else {
              await updateDoc(doc(db, "Companies", PARENT, "Users", data.user.uid), {
                loginCount: userDoc.data().loginCount + 1,
                isInactive: false,
                isLoggedIn: true,
              }).then(() => {
                navigate('/dashboard');
              });
            }
        });
    } catch (e) {
        if (e.message === "Firebase: Error (auth/wrong-password).") {
            setLoading(false);
            setError("Invalid Password");
        } else if (e.message === "Firebase: Error (auth/user-not-found).") {
            setLoading(false);
            setError("User Not Found");
        } else if (e.message === "Firebase: Error (auth/invalid-email)."){
            setLoading(false);
            setError("Invalid Email");
        }
        else {
            setLoading(false);
            setError("Something went wrong");
        }
    }
  }

  useEffect(() => {
    setShow(true)
    if (user) {
      handleLogout();
    }
  }, [])

  const springs = useSpring({
    from: { x: -300 },
    to: { x: 0 },
  })

  const handleClick = () => {
    if (email !== "" && password !== "") {
      handleSubmit();
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email"
                   label="Email address"
                   onChange={e => setEmail(e.target.value)} />

        <TextField
          name="password"
          label="Password"
          onChange={e => setPassword(e.target.value)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link variant="subtitle2" underline="hover" onClick={() => navigate('/reset')}>
          Forgot password?
        </Link>
      </Stack>
      <br />

      {email !== "" && password !== "" ?
      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>
      :
      <LoadingButton fullWidth size="large" type="submit" variant="contained" disabled>
        Login
      </LoadingButton>
      }
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link variant="subtitle2" style={{color:"white"}} underline="hover" onClick={() => navigate('/apply')}>
          {/* {show && (
            <animated.span role="img" aria-label="waving hand" style={waveAnimation}>
              &#128075;
            </animated.span>
          )} */}
          <animated.div
            style={{
              ...springs,
            }}
          >
            <span role="img" aria-label="waving hand" style={{fontSize:"18px"}}>
              &#128075;
            </span>
            &nbsp;
            Interested in joining our mentorship program?
          </animated.div>
        </Link>
      </Stack>
      <div style={{marginTop:"10px", color:"red"}}>
        {error}
      </div>
    </>
  );
}
