import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState, useRef } from 'react';
import { MoonLoader } from 'react-spinners';
import CloseIcon from '@mui/icons-material/Close';
import UploadIcon from '@mui/icons-material/Upload';
import CheckIcon from '@mui/icons-material/Check';
import { ref, listAll, getDownloadURL, uploadBytes, deleteObject } from 'firebase/storage';
import './AddModal.css';
// @mui
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
} from '@chakra-ui/react';
import {
  Card,
  Button,
  TextField,
  styled
} from '@mui/material';
// firebase
import { onSnapshot, doc, setDoc, getDocs, addDoc, getDoc, collection, updateDoc } from "firebase/firestore";
import { db, storage } from '../firebase';
import { UserAuth } from '../contexts/AuthContext';
import inviteUser from '../intake/inviteUser';
import appointOfficeManager from '../intake/appointOfficeManager';
// components
import Iconify from '../components/iconify';

const ORDER = ['State', 'Region', 'Office', 'Mentor', 'Mentee'];

const MyFileInput = (props) => {
  const fileInput = useRef(null);
  const [uploaded, setUploaded] = useState(false);

  const handleClick = () => {
    fileInput.current.click();
  };

  const handleChange = (event) => {
    const file = event.target.files[0];
    props.upload(file);
    setUploaded(true);
    // do something with the file
  };

  return (
        <>
        {!uploaded ?
        <>
        <Button onClick={handleClick}>
            <UploadIcon /> &nbsp; Upload Mentee Contract
            </Button>
            <input type="file" ref={fileInput} style={{ display: "none" }} onChange={handleChange}/>
        </>
        :
        <div style={{display:"flex", color:"green", justifyContent:"center", alignItems:"center"}}>
            <CheckIcon /> &nbsp;Uploaded Contract
        </div>
        }
        </>
    );

};

export default function AddNew(props) {
    const [loading, setLoading] = useState(false);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [locName, setLocName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [fileToUpload, setFileToUpload] = useState(null);
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [startDate, setStartDate] = useState('');
    const { createUser, logout } = UserAuth();

    async function addEntry() {
        if (props.current === 0) {
            await setDoc(doc(db, "Companies", props.location[0], "States", locName), {
                Label: locName,
                subCount: 0,
                sales: [],
                openSales: [],
            });
            props.closeModal();
        }
        else if (props.current === 1) {
            await addDoc(collection(db, "Companies", props.location[0], "States", props.location[1], "Regions"), {
                Label: locName,
                Manager: "N/A",
                sales: [],
                subCount: 0,
                openSales: [],
            });
            const stateRef = doc(db, "Companies", props.location[0], "States", props.location[1]);
            const stateData = await getDoc(stateRef);
            await updateDoc(stateRef, {
                subCount: stateData.data().subCount + 1
            })
            props.closeModal();
        }
        else if (props.current === 2) {
            await addDoc(collection(db, "Companies", props.location[0], "States", props.location[1], "Regions", props.location[2], "Offices"), {
                Label: locName,
                Managers: [],
                sales: [],
                subCount: 0,
                openSales: [],
            });
            const regRef = doc(db, "Companies", props.location[0], "States", props.location[1], "Regions", props.location[2]);
            const regData = await getDoc(regRef);
            await updateDoc(regRef, {
                subCount: regData.data().subCount + 1
            })
            props.closeModal();
        }
        else if (props.current === 3) {
            setLoading(true);
            await inviteUser(firstName, lastName, email, "Mentor", "Compass", props.location)
            const officeRef = doc(db, "Companies", props.location[0], "States", props.location[1], "Regions", props.location[2], "Offices", props.location[3]);
            const officeData = await getDoc(officeRef);
            await updateDoc(officeRef, {
                subCount: officeData.data().subCount + 1
            })
            props.closeModal();
        }
        else if (props.current === 4) {
            setLoading(true);
            const mentorDoc = await getDoc(doc(db, "Companies", props.location[0], "States", props.location[1], "Regions", props.location[2], "Offices", props.location[3], "Mentors", props.location[4]));
            const companySnap = await getDoc(doc(db, "Companies", props.location[0]));
            const curriculum = companySnap.data().curriculum;
            let qNames = ['', '', '', ''];
            if (companySnap.data().quarterNames) {
                qNames = companySnap.data().quarterNames
            }

            await updateDoc(doc(db, "Companies", props.location[0], "States", props.location[1], "Regions", props.location[2], "Offices", props.location[3], "Mentors", props.location[4]), {
                MenteeCount: mentorDoc.data().MenteeCount + 1
            })
            const menteeDoc = await addDoc(collection(db, "Companies", props.location[0], "States", props.location[1], "Regions", props.location[2], "Offices", props.location[3], "Mentors", props.location[4], "Mentees"), {
                mFirstName: firstName,
                mLastName: lastName,
                mEmail: email,
                mStart: startDate,
                mSales: 0,
                mCurriculum: curriculum,
                mQuarterNames: qNames
            });
            const mEmail = email;
            uploadFiles(menteeDoc.id);
            props.closeModal();

            const mentorRef = doc(db, "Companies", props.location[0], "States", props.location[1], "Regions", props.location[2], "Offices", props.location[3], "Mentors", props.location[4]);
            const mentorData = await getDoc(mentorRef);
            await updateDoc(mentorRef, {
                subCount: mentorData.data().subCount + 1
            })

            const tempMenteeList = companySnap.data().menteeCache;
            tempMenteeList.push({id: menteeDoc.id, 
                email: mEmail, first: firstName, 
                last: lastName, 
                location: props.location});

            await updateDoc(doc(db, "Companies", props.location[0]), {
                menteeCache: tempMenteeList
            })
        }
    }

    function uploadFiles(loc) {
        if (fileToUpload == null) return;
        const fileRef = ref(storage, `${props.location[0]}/menteeContracts/${loc}/${fileToUpload.name}`);
        uploadBytes(fileRef, fileToUpload).then(() => {
            setFileUploaded(true);
        })
    }

    return (
        <Card className='AddNewModal'>
            <div>
                {!loading && (
                    <>
                        Add New {ORDER[props.current]}
                        <CloseIcon className='CloseWindow' onClick={() => props.closeModal()}/>
                    </>
                )}
            </div>
            <br />
            {props.current <= 2 && !loading && (
                <TextField name="location"
                    label= {`${ORDER[props.current]} Name`}
                    onChange={e=>setLocName(e.target.value)}
                    style={{width:"300px"}}
                    />
            )}
            {props.current === 3 && !loading && (
                <>
                    <TextField name="firstName"
                        label= {`First Name`}
                        onChange={e=>setFirstName(e.target.value)}
                        style={{width:"300px"}}
                        />
                    <div style={{marginTop:"15px"}} />
                    <TextField name="lastName"
                        label= {`Last Name`}
                        onChange={e=>setLastName(e.target.value)}
                        style={{width:"300px"}}
                        />
                    <div style={{marginTop:"15px"}} />
                    <TextField name="email"
                        label= {`Email`}
                        onChange={e=>setEmail(e.target.value)}
                        style={{width:"300px"}}
                        />
                </>
            )}
            {props.current === 4 && !loading && (
                <>
                    <TextField name="firstName"
                        label= {`First Name`}
                        onChange={e=>setFirstName(e.target.value)}
                        style={{width:"300px"}}
                        />
                    <div style={{marginTop:"15px"}} />
                    <TextField name="lastName"
                        label= {`Last Name`}
                        onChange={e=>setLastName(e.target.value)}
                        style={{width:"300px"}}
                        />
                    <div style={{marginTop:"15px"}} />
                    <TextField name="email"
                        label= {`Email`}
                        onChange={e=>setEmail(e.target.value)}
                        style={{width:"300px"}}
                        />
                    <div style={{marginTop:"15px"}} />
                    Start Date<br />
                     <TextField name="date"
                        type='date'
                        onChange={e=>setStartDate(e.target.value)}
                        style={{width:"300px"}}
                        />
                    <div style={{marginTop:"15px"}} />
                    <MyFileInput uploaded={fileUploaded} upload={(file) => setFileToUpload(file)}/>
                </>
            )}

            {loading && (
                <>
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <MoonLoader color="#2065D1"/>
                    </div>
                    <br />
                    {props.current <= 2 && "Adding Location. Please Wait."}
                    {props.current === 3 && "Inviting new user. Please Wait."}
                    {props.current === 4 && "Adding mentee. Please Wait."}
                </>
            )}

            <div style={{marginTop:"20px"}} />

            {!loading && (
                <Button variant="contained" 
                        style={{marginLeft:"auto"}} 
                        onClick={() => addEntry()}
                        disabled={
                            (props.current === 4 && (fileToUpload === null || firstName === "" || lastName === "" || email === "")
                            || (props.current <= 2 && locName === "")
                            || (props.current === 3 && (firstName === "" || lastName === "" || email === ""))
                            ) 
                            &&
                            "true"
                            
                        }
                        startIcon={<Iconify icon="eva:plus-fill" 
                        />}>
                    Add
                </Button>
            )}
        </Card>
    );
}
