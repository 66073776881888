import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
import cslogo from '../layouts/dashboard/nav/csalone.png';
// sections
import { ApplyForm } from '../sections/auth/apply';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ApplyPage() {
  const mdUp = useResponsive('up', 'md');
  const [finished, setFinished] = useState(false);

  return (
    <>
      <Helmet>
        <title> Apply | StrongMentorship </title>
      </Helmet>

      <StyledRoot>
        {mdUp && (
          <StyledSection>
            <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }} style={{position:"fixed", width:"440px", top:"30vh"}}>
              Join the best Training Program at Compass and unlock your potential!
            </Typography>
            {/* <img src="/assets/illustrations/illustration_login.png" alt="login" /> */}
          <div style={{position:"fixed", bottom:"20px", left:"3%"}}>
          <div style={{textAlign:"center"}}>
            <div style={{display:"flex"}}>
              <img src={cslogo} alt="cslogo" style={{width:"60px", height:"52px", margin:"auto"}}/>
            </div>
                StrongMentorship
          </div>
          <hr />
          <div style={{fontSize:"12px", color:"grey", display:"flex"}}>© 2023 Software by&nbsp;
            <a href='https://strongs20.github.io/sam-portfolio' target="_blank" rel="noreferrer" className='MeLink' style={{color:"lightblue"}}>Samuel Strong</a>
          </div>
        </div>
          </StyledSection>
          
        )}

        <Container maxWidth="sm">
          {!finished ?
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Welcome to StrongMentorship!
              <br />
              Please fill out the form below
            </Typography>
            <br />
            <ApplyForm finished={() => setFinished(true)}/>
          </StyledContent>
          :
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              <div style={{color:"green"}}>
                <CheckCircleIcon /> Form Complete!
              </div>
              <br />
              Thank you for taking the time to fill out the application.
              Someone from Compass will be in touch with you. 
              Looking forward to seeing you soon!
              <br /><br />
              Craig Strong - Lead Mentor
            </Typography>
          </StyledContent>
          }
        </Container>
      </StyledRoot>
    </>
  );
}
