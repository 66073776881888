// ----------------------------------------------------------------------

export default function Table(theme) {
  return {
    MuiTableCell: {
      borderBottomWidth: `2px solid`,
      borderColor: `#333333`,
      styleOverrides: {
        head: {
          borderBottomWidth: `2px solid`,
          borderColor: `#333333`,
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.background.neutral,
        },
      },
    },
  };
}
