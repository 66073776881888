import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import EmailIcon from '@mui/icons-material/Email';
import { Toolbar, 
  Tooltip, 
  TextField,
  IconButton, 
  Typography, 
  OutlinedInput, 
  InputAdornment,
  Button } from '@mui/material';
// component
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  fontColor:"#FFFFFF !important",
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    fontColor:"#FFFFFF !important",
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    fontColor:"#FFFFFF !important",
    borderWidth: `1px !important`,
    borderColor: `${alpha('#FFFFFF', 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function UserListToolbar({ numSelected, filterName, onFilterName, location, locLabel, openMass }) {
  if (locLabel === undefined) {
    return("");
  }
  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      <div style={{fontWeight:"600", fontSize:"20px", display:"flex"}}>
        {locLabel !== "Users" ?
        <>
        {locLabel[0].toUpperCase() + locLabel.slice(1)} {location}s
        </>
        : "Users"}

      </div>

      {locLabel === "Users" && (
          <div style={{marginLeft:'auto', marginRight:'20px'}}>
            <Button 
              variant='contained'
              onClick={() => openMass()}
              ><EmailIcon/>&nbsp;&nbsp;New Mass Email
            </Button>
          </div>
      )}
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <StyledSearch
          value={filterName}
          onChange={onFilterName}
          placeholder="Search..."
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <Iconify icon="eva:trash-2-fill" />
          </IconButton>
        </Tooltip>
      ) : (
        <></>
      )}
    </StyledRoot>
  );
}
