import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import { Container, Stack, Typography, ToggleButton, ToggleButtonGroup, Button, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import './MenteeCurriculum.css'
// components
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getDoc, doc, setDoc, updateDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import { UserAuth } from '../contexts/AuthContext';

// ----------------------------------------------------------------------
const PARENT = window.location.host.split('.')[0];

export default function MenteeCurriculum() {
  const [onPage, setOnPage] = useState(1);
  const [isAdmin, setIsAdmin] = useState(false);
  const [newSectionName, setNewSectionName] = useState('');
  const [newCheckName, setNewCheckName] = useState('');
  const [areYouSure, setAreYouSure] = useState(false);
  const [loading, setLoading] = useState(false);
  const [curriculum, setCurriculum] = useState([]);
  const [totalCurriculum, setTotalCurriculum] = useState([]);
  const [quarterNames, setQuarterNames] = useState(['', '', '', '']);
  const [selectedAccordion, setSelectedAccordion] = useState('');
  const [newSectionOrder, setNewSectionOrder] = useState(0);
  const { user } = UserAuth();

  function handleAccordionClick(index) {
    if (selectedAccordion === index) {
      setSelectedAccordion('');
    } else {
      setSelectedAccordion(index);
    }
  }

  async function enforcePermissions(type, page) {
    if (type === "Admin") {
      setIsAdmin(true);
    }
    const companySnap = onSnapshot(doc(db, "Companies", PARENT), (doc) => {
        if (doc.data().curriculum) {
          setTotalCurriculum(doc.data().curriculum);
          const thisCurric = doc.data().curriculum.filter((section) => section.quarter === page)
          if (doc.data().quarterNames) {
            setQuarterNames(doc.data().quarterNames);
          }
          thisCurric.sort((a, b) => a.order - b.order);
          setCurriculum(thisCurric);
        }
      });
      setLoading(false);
  }

  async function updateQuarterName(value) {
    const tempNames = quarterNames;
    tempNames[onPage-1] = value;
    setQuarterNames(tempNames);
    await updateDoc(doc(db, 'Companies', PARENT), {
      quarterNames: tempNames
    })
  }

  async function addSection() {
    if (!curriculum.map((section) => section.name).includes(newSectionName)) {
      setNewSectionName('');
      await updateDoc(doc(db, 'Companies', PARENT), {
        curriculum: [...totalCurriculum, {name: newSectionName, quarter: onPage, checks: [], order: curriculum.length+1, notes: []}]
      })
    }
  }

  async function addCheck(section) {
    const newCurric = totalCurriculum;
    for (let i = 0; i < newCurric.length; i+=1) {
      if (newCurric[i].name === section.name && newCurric[i].quarter === onPage && !newCurric[i].checks.includes(newCheckName)) {
        newCurric[i].checks.push({name: newCheckName, order: newCurric[i].checks.length+1, checked:false});
        setNewCheckName('');
      }
    }
    updateDoc(doc(db, 'Companies', PARENT), {
      curriculum: newCurric
    })
  }

  function switchPage(val) {
    setOnPage(val);
    enforcePermissions("Admin", val);
    setSelectedAccordion('');
  }

  function removeCheck(index) {
    const newCurric = totalCurriculum;
    for (let i = 0; i < totalCurriculum.length; i+=1) {
      if (totalCurriculum[i].name === curriculum[selectedAccordion].name && totalCurriculum[i].quarter === onPage) {
        newCurric[i].checks.splice(index, 1);
        newCurric[i].checks.sort((a, b) => a.order - b.order);
        for (let j = 0; j < newCurric[i].checks.length; j+=1) {
          newCurric[i].checks[j].order = j+1;
        }
      }
    }
    updateDoc(doc(db, 'Companies', PARENT), {
      curriculum: newCurric
    })
  }

  async function moveDownCheck(index) {
    const newCurric = totalCurriculum;
    for (let i = 0; i < totalCurriculum.length; i+=1) {
      if (totalCurriculum[i].name === curriculum[selectedAccordion].name && totalCurriculum[i].quarter === onPage) {
        newCurric[i].checks[index].order += 1;
        newCurric[i].checks[index+1].order -= 1;
        newCurric[i].checks.sort((a, b) => a.order - b.order);
      }
    }
    updateDoc(doc(db, 'Companies', PARENT), {
      curriculum: newCurric
    })
  }

  async function moveUpCheck(index) {
    const newCurric = totalCurriculum;
    for (let i = 0; i < totalCurriculum.length; i+=1) {
      if (totalCurriculum[i].name === curriculum[selectedAccordion].name && totalCurriculum[i].quarter === onPage) {
        console.log("before", newCurric[i].checks)
        newCurric[i].checks[index].order -= 1;
        newCurric[i].checks[index-1].order += 1;
        newCurric[i].checks.sort((a, b) => a.order - b.order);
      }
    }
    updateDoc(doc(db, 'Companies', PARENT), {
      curriculum: newCurric
    })
  }

  function removeSection(index) {
    if (!areYouSure) {
      setAreYouSure(true);
    } else {
      setAreYouSure(false);
      const curricOtherQuarters = [];
      const thisCurric = [];
      setSelectedAccordion('');
      for (let i = 0; i < totalCurriculum.length; i+=1) {
        if (totalCurriculum[i].quarter !== onPage) {
          curricOtherQuarters.push(totalCurriculum[i]);
        } else if (totalCurriculum[i].quarter === onPage && totalCurriculum[i].name !== curriculum[index].name) {
          thisCurric.push(totalCurriculum[i]);
        }
      }
      thisCurric.sort((a, b) => a.order - b.order);
      for (let i = 0; i < thisCurric.length; i+=1) {
        thisCurric[i].order = i+1;
      }
      const newCurric = [...curricOtherQuarters, ...thisCurric];
      updateDoc(doc(db, 'Companies', PARENT), {
        curriculum: newCurric
      })
    }
  }

  async function moveUp(index) {
    const newCurric = curriculum;
    newCurric[index-1].order += 1;
    newCurric[index].order -= 1;
    setCurriculum(newCurric);

    const curricOtherQuarters = [];
    for (let i = 0; i < totalCurriculum.length; i+=1) {
      if (totalCurriculum[i].quarter !== onPage) {
        curricOtherQuarters.push(totalCurriculum[i]);
      }
    }

    updateDoc(doc(db, 'Companies', PARENT), {
        curriculum: [...curricOtherQuarters, ...curriculum]
    })
  }

  async function moveDown(index) {
    const newCurric = curriculum;
    newCurric[index+1].order -= 1;
    newCurric[index].order += 1;
    setCurriculum(newCurric);

    const curricOtherQuarters = [];
    for (let i = 0; i < totalCurriculum.length; i+=1) {
      if (totalCurriculum[i].quarter !== onPage) {
        curricOtherQuarters.push(totalCurriculum[i]);
      }
    }

    updateDoc(doc(db, 'Companies', PARENT), {
        curriculum: [...curricOtherQuarters, ...curriculum]
    })
  }

  useEffect(() => {
    user.getIdTokenResult().then(async (idTokenResult) => {
      enforcePermissions(idTokenResult.claims.accountType, onPage);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title> Admin: Mentee Curriculum</title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Mentee Curriculum
        </Typography>

          <ToggleButtonGroup
            exclusive
            aria-label="text alignment"
          >
            <ToggleButton style={onPage === 1 ? {background:"#444444"} : {}} value="left" aria-label="left aligned" onClick={() => switchPage(1)} disabled={onPage === 1}>
              1
            </ToggleButton>
            <ToggleButton style={onPage === 2 ? {background:"#444444"} : {}} value="center" aria-label="centered" onClick={() => switchPage(2)} disabled={onPage === 2}>
              2
            </ToggleButton>
            <ToggleButton style={onPage === 3 ? {background:"#444444"} : {}} value="right" aria-label="right aligned" onClick={() => switchPage(3)} disabled={onPage === 3}>
              3
            </ToggleButton>
            <ToggleButton style={onPage === 4 ? {background:"#444444"} : {}} value="justify" aria-label="justified" onClick={() => switchPage(4)} disabled={onPage === 4}>
              4
            </ToggleButton>
          </ToggleButtonGroup>

          &nbsp;&nbsp;&nbsp;&nbsp; Quarter {onPage} title: &nbsp;&nbsp;
          {isAdmin ?
          <TextField  style={{marginTop:"5px"}} 
                      variant="standard"
                      value={quarterNames[onPage-1]}
                      onChange={e => updateQuarterName(e.target.value)}
                      />
          :
          <>{quarterNames[onPage-1]}</>
          }

          {isAdmin && <div style={{display:"flex"}}>
            <div style={{marginLeft:"auto", marginTop:"-60px"}}>
              <TextField name="firstName"
                        label= {`New Section Name`}
                        onChange={e=>setNewSectionName(e.target.value)}
                        value={newSectionName}
                        style={{width:"300px"}}
                        />
              &nbsp;&nbsp;
              <Button variant="contained" 
                            style={{marginLeft:"auto", height:"56px"}} 
                            onClick={() => {addSection()}}
                            ><AddIcon/></Button>
            </div>
          </div>}
          <div style={{marginTop:"30px"}} />
          <div className='AccordionPH'>
              {curriculum.map((section, index) => (
                <div style={{display:"flex", marginBottom:"5px"}}>
                  <Accordion
                  style={{width:"100%"}}
                  className='CAccordion'
                  expanded={selectedAccordion === index}
                  >
                    
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    style={{background:"#3A3A3A"}}
                    id="panel1a-header"
                    onClick={() => handleAccordionClick(index)}
                  >
                    <Typography>{section.name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className='ChecklistItems'>
                          {section.checks
                          .map((check, ind) => (
                            <div className='CheckItem'>
                              {check.name}
                              {isAdmin && <>
                              <button style={{marginLeft:"auto"}} onClick={() => removeCheck(ind)}>
                                Remove Item
                              </button>
                              <div className="moveAccordion" style={{width:"60px", justifyContent:"center", alignItems:"center", background:"none"}}>
                                {ind !== 0 && (
                                  <KeyboardArrowUpIcon className='ArrowMove' onClick={() => moveUpCheck(ind)}/> 
                                )}
                                {ind !== section.checks.length-1 && (
                                  <KeyboardArrowDownIcon className='ArrowMove' onClick={() => moveDownCheck(ind)}/>
                                )}
                              </div>
                              </>}
                            </div>
                          ))}
                          {section.checks.length === 0 && (<div style={{margin:"35px"}}>No checklist items yet. Add below.</div>)}
                      </div>
                      {isAdmin && <div style={{marginTop:"20px", display:"flex"}}>
                          <TextField name="firstName"
                                    label= {`New Checklist Item`}
                                    onChange={e=>setNewCheckName(e.target.value)}
                                    variant="standard"
                                    value={newCheckName}
                                    style={{width:"300px"}}
                                    />
                          &nbsp;&nbsp;
                          <Button variant="standard" 
                                        style={{height:"50px", maxWidth:"30px"}} 
                                        onClick={() => {addCheck(section)}}
                                        ><AddIcon/></Button>
                          <Button style={{marginLeft:"auto"}} onClick={() => removeSection(index)}>
                            Remove Section
                          </Button>
                          {areYouSure && (
                              <div className='AYSModal'>
                                Are you sure you want to delete this section?
                                <div style={{marginTop:"20px"}}>
                                  <Button variant="standard" 
                                          style={{height:"50px", maxWidth:"30px"}} 
                                          onClick={() => {setAreYouSure(false)}}
                                  >No</Button>
                                  <Button color="error" 
                                          style={{height:"50px", maxWidth:"30px"}} 
                                          onClick={() => {removeSection(index)}}
                                  >Yes</Button>
                                </div>
                              </div>
                            )}

                        </div>}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                {selectedAccordion !== index && isAdmin && (
                <div className="moveAccordion">
                  Move 
                  {index !== 0 && (
                    <KeyboardArrowUpIcon className='ArrowMove' onClick={() => moveUp(index)}/> 
                  )}
                  {index !== curriculum.length-1 && (
                    <KeyboardArrowDownIcon className='ArrowMove' onClick={() => moveDown(index)}/>
                  )}
                </div>
                )}
              </div>
              ))}
          </div>
      </Container>
    </>
  );
}
