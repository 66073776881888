import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import Mainpage from './pages/Mainpage';
import LoginPage from './pages/LoginPage';
import ResetPwPage from './pages/ResetPwPage';
import RegisterPage from './pages/RegisterPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/MenteeCurriculum';
import DashboardAppPage from './pages/DashboardAppPage';
import Viewers from './pages/Viewers';
import Help from './pages/Help';
import Userpage from './pages/UserPage';
import IdleChecker from './IdleChecker';
import Zoompage from './pages/Zoompage';
import SalesPage from './pages/SalesPage';
import ApplyPage from './pages/ApplyPage';
import MenteeContract from './pages/MenteeContract';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <LoginPage />,
    },
    {
      path: '/reset',
      element: <ResetPwPage />,
    },
    {
      path: '/register',
      element: <RegisterPage />,
    },
     {
      path: '/apply',
      element: <ApplyPage />,
    },
    {
      path: '/dashboard',
      element: 
        <IdleChecker>
          <DashboardLayout />
        </IdleChecker>,
      children: [
        { element: <Navigate to="/dashboard/stats" />, index: true },
        { path: 'stats', element: <DashboardAppPage /> },
        { path: 'sales', element: <SalesPage /> },
        { path: 'users', element: <Userpage /> },
        { path: 'contract', element: <MenteeContract /> },
        { path: 'menteecurriculum', element: <ProductsPage /> },
        { path: 'viewers', element: <Viewers /> },
        { path: 'help', element: <Help /> },
        { path: 'zoom', element: <Zoompage /> },
        { path: 'states', element: <Mainpage />, children: [
            { path: ':stateID', children: [
              { path: 'regions', children: [
                  { path: ':regionID', children: [
                    { path: 'offices', children: [
                      { path: ':officeID', children: [
                        { path: 'mentors', children: [
                          { path: ':mentorID', children: [
                            { path: 'mentees'}
                          ] }
                        ] }
                      ] }
                    ] }
                  ] }
                ]
              }
            ] }
          ]
        },
        // { path: 'products', element: <ProductsPage /> },
        // { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      path: '*',
      element: <Page404 />,
    },
  ]);

  return routes;
}
