import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { MoonLoader } from 'react-spinners';
import CloseIcon from '@mui/icons-material/Close';
import GetAppIcon from '@mui/icons-material/GetApp';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SchoolIcon from '@mui/icons-material/School';
import ReactQuill from 'react-quill';
import './MenteeModal.css';
import '../AddModal.css';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  TextField,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  ToggleButton, 
  ToggleButtonGroup
} from '@mui/material';
// firebase
import { onSnapshot, doc, setDoc, getDocs, addDoc, getDoc, collection, updateDoc } from "firebase/firestore";
import { ref, listAll, getDownloadURL, uploadBytes, deleteObject } from 'firebase/storage';
import EscrowReport from './EscrowReport';
import { db, storage } from '../../firebase';
import { UserAuth } from '../../contexts/AuthContext';
import inviteUser from '../../intake/inviteUser';
// components
import Iconify from '../../components/iconify';

export default function MenteeModal(props) {
    const [loading, setLoading] = useState(false);
    const [onPage, setOnPage] = useState(1);
    const [menteeObj, setMenteeObj] = useState({});
    const [locName, setLocName] = useState('');
    const [curriculum, setCurriculum] = useState([]);
    const [quarterNames, setQuarterNames] = useState(['', '', '', '']);
    const [curricOtherQ, setCurricOtherQ] = useState([]);
    const [quartersCompleted, setQuartersCompleted] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [showEscrow, setShowEscrow] = useState(false);

    const [notes, setNotes] = useState([]);
    const [viewNote, setViewNote] = useState('');
    const [viewNoteTitle, setViewNoteTitle] = useState('');
    const [showNotes, setShowNotes] = useState('');
    const [viewCreateNote, setViewCreateNote] = useState(false);
    const [viewEditNote, setViewEditNote] = useState(false);
    const [viewEditNoteIndex, setViewEditNoteIndex] = useState(0);

    const { createUser, logout } = UserAuth();

    async function retrieveMentee(page) {
        const menteeSnap = await getDoc(doc(db, "Companies", props.location[0], "States", props.location[1], "Regions", props.location[2], "Offices", props.location[3], "Mentors", props.location[4], "Mentees", props.mentee));
        setMenteeObj(menteeSnap.data());
        setQuarterNames(menteeSnap.data().mQuarterNames);
        const curric = [];
        const oCurric = [];
        const qComplete = [];

        const q1 = [];
        const q2 = [];
        const q3 = [];
        const q4 = [];

        menteeSnap.data().mCurriculum.forEach((section) => {
            if (section.quarter === page) {
                curric.push(section);
            } else {
                oCurric.push(section)
            }
            if (section.quarter === 1) {
                if (section.checks.every(check => check.checked)) {
                    q1.push(1)
                } else {
                    q1.push(0)
                }
            } else if (section.quarter === 2) {
                if (section.checks.every(check => check.checked)) {
                    q2.push(1)
                } else {
                    q2.push(0)
                }
            } else if (section.quarter === 3) {
                if (section.checks.every(check => check.checked)) {
                    q3.push(1)
                } else {
                    q3.push(0)
                }
            } else if (section.quarter === 4) {
                if (section.checks.every(check => check.checked)) {
                    q4.push(1)
                } else {
                    q4.push(0)
                }
            }
        })

        if (q1.every(val => val === 1)) {
            qComplete.push(1);
        }
        if (q2.every(val => val === 1)) {
            qComplete.push(2);
        }
        if (q3.every(val => val === 1)) {
            qComplete.push(3);
        }
        if (q4.every(val => val === 1)) {
            qComplete.push(4);
        }
        
        curric.sort((a, b) => a.order - b.order);

        setCurriculum(curric);
        setCurricOtherQ(oCurric);
        setQuartersCompleted(qComplete);
        setLoading(false);
    }

    useEffect(() => {
        retrieveMentee(1);
    }, []);

    function switchPage(val) {
        setOnPage(val);
        retrieveMentee(val);
    }

    const handleClick = (event) => {
        console.log(event);
    };

    async function downloadContract() {
        const fileListRef = ref(storage, `${props.location[0]}/menteeContracts/${props.mentee}/`);
        await listAll(fileListRef).then((response) => {
            response.items.forEach((item) => {
                getDownloadURL(item).then(url => window.open(url));
            })
        })
    }

    async function checkBox(si, ci) {
        const tempCurric = curriculum;
        tempCurric[si].checks[ci].checked = !tempCurric[si].checks[ci].checked;

        updateDoc(doc(db, "Companies", props.location[0], "States", props.location[1], "Regions", props.location[2], "Offices", props.location[3], "Mentors", props.location[4], "Mentees", props.mentee), {
            mCurriculum: [...tempCurric, ...curricOtherQ]
        })
        setCurriculum(tempCurric);
        retrieveMentee(onPage);
    }

    function openNotes(section, i) {
        setNotes(section.notes);
        setShowNotes(section.name);
    }

    async function addNote() {
        if (viewNoteTitle === '' || viewNote === '') {
            return(-1);
        }
        const tempCurric = curriculum;
        tempCurric.forEach((section) => {
            if (section.name === showNotes) {
                section.notes.push({
                    title: viewNoteTitle,
                    note: viewNote
                })
                setNotes(section.notes);
            }
        })

        await updateDoc(doc(db, "Companies", props.location[0], "States", props.location[1], "Regions", props.location[2], "Offices", props.location[3], "Mentors", props.location[4], "Mentees", props.mentee), {
            mCurriculum: [...tempCurric, ...curricOtherQ]
        })
        setViewNoteTitle('');
        setViewNote('');
        setViewCreateNote(false);
        setNoteToDelete(null);

        retrieveMentee(onPage);
        return(1);
    }

    function editNote(note, i) {
        setViewNoteTitle(note.title);
        setViewNote(note.note);
        setViewEditNote(true);
        setViewEditNoteIndex(i);
    }

    const [noteToDelete, setNoteToDelete] = useState(null);

    function promptConfirmDelete(i) {
        console.log(i);
        setNoteToDelete(i);
    }

    async function deleteNote() {
        // delete a note
        const tempCurric = curriculum;
        tempCurric.forEach((section) => {
            if (section.name === showNotes) {
                section.notes.splice(noteToDelete, 1);
                setNotes(section.notes);
            }
        })
        // update the doc
        await updateDoc(doc(db, "Companies", props.location[0], "States", props.location[1], "Regions", props.location[2], "Offices", props.location[3], "Mentors", props.location[4], "Mentees", props.mentee), {
            mCurriculum: [...tempCurric, ...curricOtherQ]
        })
        retrieveMentee(onPage);
        setNoteToDelete(null);
    }

    function saveNote() {
        const tempCurric = curriculum;
        tempCurric.forEach((section) => {
            if (section.name === showNotes) {
                // update accordingly
                section.notes[viewEditNoteIndex].title = viewNoteTitle;
                section.notes[viewEditNoteIndex].note = viewNote;
                setNotes(section.notes);
            }
        })

        updateDoc(doc(db, "Companies", props.location[0], "States", props.location[1], "Regions", props.location[2], "Offices", props.location[3], "Mentors", props.location[4], "Mentees", props.mentee), {
            mCurriculum: [...tempCurric, ...curricOtherQ]
        })
        setViewNoteTitle('');
        setViewNote('');
        setViewEditNote(false);

        retrieveMentee(onPage);
    }

    return (
        <>
        {true ? 
        
        <Card className='MenteeModal'>
            <div>
                <div style={{fontSize:"21px"}}>
                    {!showEscrow && (
                        <div style={{fontSize: "18px" ,position:"absolute", left:"30px", top:"80px"}}>
                            Quarters completed:&nbsp;&nbsp;
                            {quartersCompleted.map((q, i) => {
                                return (<>{q}{i < quartersCompleted.length-1 && quartersCompleted.length > 1 && ", "} </>)
                            })}
                        </div>
                    )}
                    {menteeObj.mFirstName} {menteeObj.mLastName}
                </div>
                <CloseIcon className='CloseWindow' onClick={() => props.closeModal()}/>
                <Button variant="contained" color={showEscrow ? "primary" : "success"} style={{position:"absolute", width:"155px", right:"16px", top:"40px"}} onClick={() => setShowEscrow(!showEscrow)}>
                    {showEscrow ? <SchoolIcon style={{fontSize:"15px"}}/> : <RequestQuoteOutlinedIcon style={{fontSize:"15px"}}/>} &nbsp; {showEscrow ? "View Course" : "Report Escrow"}
                </Button>
                <Button variant="contained" style={{position:"absolute", width:"155px", right:"16px", top:"90px"}} onClick={() => downloadContract()}>
                    <GetAppIcon style={{fontSize:"15px", marginBottom:"-3px"}}/> &nbsp; View Contract
                </Button>
                {!showEscrow && (
                    <div className='QSelector'>
                        <div>Quarter: &nbsp;&nbsp;
                            <ToggleButtonGroup
                                exclusive
                                aria-label="text alignment"
                            >
                                <ToggleButton style={onPage === 1 ? {background:"#444444"} : {}} value="left" aria-label="left aligned" onClick={() => switchPage(1)} disabled={onPage === 1}>
                                1
                                </ToggleButton>
                                <ToggleButton style={onPage === 2 ? {background:"#444444"} : {}} value="center" aria-label="centered" onClick={() => switchPage(2)} disabled={onPage === 2}>
                                2
                                </ToggleButton>
                                <ToggleButton style={onPage === 3 ? {background:"#444444"} : {}} value="right" aria-label="right aligned" onClick={() => switchPage(3)} disabled={onPage === 3}>
                                3
                                </ToggleButton>
                                <ToggleButton style={onPage === 4 ? {background:"#444444"} : {}} value="justify" aria-label="justified" onClick={() => switchPage(4)} disabled={onPage === 4}>
                                4
                                </ToggleButton>
                        </ToggleButtonGroup>
                        </div>
                    
                    </div>
                )}
                {showEscrow && (
                    <div className='QSelector'>
                        Escrow Reporting Form
                    </div>
                )}
            </div>
            <br />
            <div className='MenteeSections'>
            {showNotes && (<div className='NotesModal' style={{width:"340px"}}>
                {!viewCreateNote && !viewEditNote && (
                    <div>
                        Notes for: {showNotes} 
                        <br />
                        {`(${menteeObj.mFirstName} ${menteeObj.mLastName})`}
                        <div style={{position:"absolute", right:"0px", top:"0px"}}>
                            <CloseIcon className='CloseWindow' onClick={() => setShowNotes('')}/>
                        </div>
                        <div style={{margin:"10px", marginBottom:"15px"}}>
                            <button onClick={() => setViewCreateNote(true)}>+ Create New Note</button>
                        </div>
                        <div className='NotesList'>
                            {notes.map((note, i) => {
                                return(
                                    <div className='NoteRow'>
                                        {note.title}
                                        <div style={{marginLeft:"auto", marginRight:"10px"}}>
                                            {noteToDelete === i ?
                                             <>
                                                <button onClick={() => setNoteToDelete(null)}>Cancel</button>
                                                <button onClick={() => deleteNote()} style={{color:"red", marginLeft:"5px"}}>Confirm Delete</button>
                                             </>
                                            :
                                             <>
                                                <button onClick={() => editNote(note, i)}>View</button>
                                                <button onClick={() => promptConfirmDelete(i)} style={{color:"red", marginLeft:"5px"}}>Delete</button>
                                             </>
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}
                {viewCreateNote && (
                    <div>
                        New Note Title <input style={{backgroundColor: "transparent", color: "white", fontSize:"18px", borderStyle:"none", borderBottomStyle:"solid"}}
                            onChange={e=>setViewNoteTitle(e.target.value)} value={viewNoteTitle}/>
                        <div style={{position:"absolute", right:"0px", top:"0px"}}>
                            <CloseIcon className='CloseWindow' onClick={() => setShowNotes('')}/>
                        </div>
                        <div style={{marginTop:"20px", marginBottom:"10px"}}>
                            <textarea style={{backgroundColor: "#222222", color: "white", width:"300px", height:"170px", resize:"none", fontSize:"18px"}}
                            onChange={e=>setViewNote(e.target.value)} value={viewNote}/>
                        </div>
                        <Button variant="contained" style={{marginRight:"10px"}} onClick={() => addNote()}>Add</Button>
                        <Button onClick={() => setViewCreateNote(false)}>Cancel</Button>
                    </div>
                )}
                {viewEditNote && (
                    <div>
                        Note Title <input style={{backgroundColor: "transparent", color: "white", fontSize:"18px", borderStyle:"none", borderBottomStyle:"solid"}}
                            onChange={e=>setViewNoteTitle(e.target.value)} value={viewNoteTitle}/>
                        <div style={{position:"absolute", right:"0px", top:"0px"}}>
                            <CloseIcon className='CloseWindow' onClick={() => setShowNotes('')}/>
                        </div>
                        <div style={{marginTop:"20px", marginBottom:"10px"}}>
                            <textarea style={{backgroundColor: "#222222", color: "white", width:"300px", height:"170px", resize:"none", fontSize:"18px"}}
                            onChange={e=>setViewNote(e.target.value)} value={viewNote}/>
                        </div>
                        <Button variant="contained" style={{marginRight:"10px"}} onClick={() => saveNote()}>Save</Button>
                        <Button onClick={() => setViewEditNote(false)}>Cancel</Button>
                    </div>
                )}
            </div>)}
            {showEscrow ?
                <EscrowReport location={props.location} mentee={props.mentee}/>
                :
                <div style={{marginTop:"-20px"}}>
                    <h3>
                        {quarterNames[onPage-1]}
                    </h3>
                    {curriculum.map((section, i) => {
                        return(
                            <div style={{textAlign:"left"}}>
                                <div className='MCurriculumSection'>
                                    <div className='SectionTitle'>
                                        <div style={{display:"flex"}}>
                                            <button style={{marginLeft:"10px"}} onClick={() => openNotes(section, i)}>Notes</button> 
                                            <div style={section.notes.length > 0 ? {marginLeft:"10px", marginRight:"-60px", color:"lightblue"} : {marginLeft:"10px", marginRight:"-60px", color:"lightgray"}}>
                                                {`(${section.notes.length})`}
                                            </div>
                                        </div>

                                        <div style={{margin:"auto"}}>
                                            {section.name}
                                        </div>

                                        <div style={{position:"relative", right:"8%", top:"2px"}}>
                                            {section.checks.every(check => check.checked) && (<CheckCircleIcon style={{fontSize:"30px"}} className='SComplete'/>)}
                                        </div>
                                    </div>
                                    {section.checks.map((check, j) => {
                                        return(
                                            <div>
                                                <Checkbox checked={check.checked} onChange={(event) => checkBox(i, j)} />
                                                {check.name}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            }
            </div>
        </Card>
        :
    
        "loading mentee"}
        </>
    );
}
