import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
import { useEffect, useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
import { getDoc, doc, getDocs, collection } from 'firebase/firestore';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { db } from '../firebase';
import inviteUser from '../intake/inviteUser';
import Chart, { useChart } from '../components/chart';

// ----------------------------------------------------------------------
function formatNumberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const PARENT = window.location.host.split('.')[0];

export default function DashboardAppPage() {
  const [adminFirst, setAdminFirst] = useState('');
  const [adminLast, setAdminLast] = useState('');
  const [adminEmail, setAdminEmail] = useState('');

  const [chartLabels, setChartLabels] = useState([]);
  const [totalSales, setTotalSales] = useState('');
  const [stateData, setStateData] = useState([]);
  const [closingData, setClosingData] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [openingData, setOpeningData] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [topOffices, setTopOffices] = useState([])
  const [closingCountData, setClosingCountData] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [openingCountData, setOpeningCountData] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

  async function inviteAdmin() {
    await inviteUser(adminFirst, adminLast, adminEmail, 'Admin', PARENT, [PARENT]);
    setAdminFirst('');
    setAdminLast('');
    setAdminEmail('');
  }

  async function getData() {
    const currentDate = new Date(); // get current date
    const months = []; // initialize array

    // loop through past 12 months
    for (let i = 0; i < 12; i+=1) {
      const pastDate = new Date(); // create new date object
      pastDate.setMonth(currentDate.getMonth() - i); // set month to past month
      const month = pastDate.getMonth() + 1 < 10 ? `0${pastDate.getMonth() + 1}` : pastDate.getMonth() + 1; // format month with leading zero if needed
      const year = pastDate.getFullYear().toString().slice(-2); // get last 2 digits of year
      const formattedDate = `${month}/01/${year}`; // format date as day/year
      months.push(formattedDate); // add date to array
    }
    console.log(months)

    setChartLabels(months);
    const parentSnap = await getDoc(doc(db, "Companies", PARENT));
    setTotalSales(formatNumberWithCommas(parentSnap.data().totalSales));

    // get state values
    const states = await getDocs(collection(db, "Companies", PARENT, "States"));
    const stateList = [];
    const stateSales = [];
    const tempStateList = [];
    states.forEach((state) => {
      stateList.push(state.id);
      stateSales.push(0);
    })

    const tempClosingDat = [0,0,0,0,0,0,0,0,0,0,0,0];
    const tempClosingCount = [0,0,0,0,0,0,0,0,0,0,0,0];
    const officeCounts = {};
    const officeLocations = {};
    parentSnap.data().sales.forEach((sale) => {
      // get first 3 elements of location
      officeCounts[sale.location[3]] = officeCounts[sale.location[3]] ? officeCounts[sale.location[3]] + 1 : 1;
      officeLocations[sale.location[3]] = sale.location.slice(0, 3);

      const month = sale.date.slice(5, 7);
      const year = sale.date.slice(2, 4);
      // get last 2 digits of year
      // const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate(); // format day with leading zero if needed
      const formattedDate = `${month}/01/${year}`; // format date as day/year
      const index = months.indexOf(formattedDate);
      if (index !== -1) {
        tempClosingDat[index] += Number(sale.amount);
        tempClosingCount[index] += 1;
      }

      const stateIndex = stateList.indexOf(sale.location[1]);
      if (stateIndex !== -1) {
        stateSales[stateIndex] += Number(sale.amount);
      }
    });

    stateList.forEach((state, index) => {
      tempStateList.push({ label: state, value: stateSales[index] });
    });

    setStateData(tempStateList);

    const tempOpeningDat = [0,0,0,0,0,0,0,0,0,0,0,0];
    const tempOpeningCount = [0,0,0,0,0,0,0,0,0,0,0,0];
    parentSnap.data().openSales.forEach((sale) => {
      const month = sale.date.slice(5, 7);
      const year = sale.date.slice(2, 4);
      // get last 2 digits of year
      // const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate(); // format day with leading zero if needed
      const formattedDate = `${month}/01/${year}`; // format date as day/year
      const index = months.indexOf(formattedDate);
      if (index !== -1) {
        tempOpeningDat[index] += Number(sale.amount);
        tempOpeningCount[index] += 1;
      }
    });

    // subtract the each index of the closing data from the opening data
    // for (let i = 0; i < tempOpeningDat.length; i+=1) {
    //   tempOpeningDat[i] -= tempClosingDat[i];
    // }

    setClosingData(tempClosingDat);
    setOpeningData(tempOpeningDat);
    setClosingCountData(tempClosingCount);
    setOpeningCountData(tempOpeningCount);

    const officeData = [];
    await Promise.all(Object.keys(officeCounts).map(async (office) => {
      const officeLoc = officeLocations[office];
      const officeSnap = await getDoc(doc(db, "Companies", officeLoc[0], "States", officeLoc[1], "Regions", officeLoc[2], "Offices", office));
      officeData.push({ label: officeSnap.data().Label, value: officeCounts[office] });
    }));
    setTopOffices(officeData);
  }

  useEffect(() => {
    getData();
  }, [])
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title> Dashboard | StrongMentorship </title>
      </Helmet>

     {false && (<div>
        <input placeholder ={'Admin firstName'} onChange={(e) => setAdminFirst(e.target.value)}/>
        <input placeholder ={'Admin lastName'} onChange={(e) => setAdminLast(e.target.value)}/>
        <input placeholder ={'Admin email'} onChange={(e) => setAdminEmail(e.target.value)}/>
        <button onClick={() => inviteAdmin()}>Invite New Admin</button>
      </div>)
    }
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back to StrongMentorship
        </Typography>

        <Grid container spacing={3}>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Company-wide Mentorship Closings"
              subheader={`All time mentorship closings: $${totalSales}`}
              chartLabels={chartLabels}
              chartData={[
                {
                  name: 'Market value of closings',
                  type: 'area',
                  fill: 'gradient',
                  color: theme.palette.success.main,
                  data: closingData,
                },
                // {
                //   name: 'Market value of openings',
                //   type: 'area',
                //   fill: 'gradient',
                //   data: openingData,
                // },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Breakdown by state"
              chartData={stateData}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Openings and Closings (volume)"
              chartLabels={chartLabels}
              chartData={[
                {
                  name: 'Total opening volume',
                  type: 'column',
                  fill: 'solid',
                  color: theme.palette.warning.main,
                  data: openingCountData,
                },
                                {
                  name: 'Total closing volume',
                  type: 'column',
                  fill: 'solid',
                  color: theme.palette.primary.main,
                  data: closingCountData,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Top Offices (Sales Volume)"
              chartData={topOffices}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>

        </Grid>
      </Container>
    </>
  );
}
