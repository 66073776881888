import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { CircleLoader, BarLoader, PulseLoader } from 'react-spinners';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Row.css';
// @muia
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
} from '@chakra-ui/react';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  TextField,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
// firebase
import { onSnapshot, doc, deleteDoc, collection, updateDoc, getDocs, getDoc } from "firebase/firestore";
import EditName from './EditName';
import { UserAuth } from '../contexts/AuthContext';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import AddNew from './AddNew';
import AddManager from './AddManager';
import ViewManagers from './ViewManagers';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';

import { db, deleteUser } from '../firebase';
import MenteeModal from './MenteeModal/MenteeModal';

// ----------------------------------------------------------------------

const ORDER = ['State', 'Region', 'Office', 'Mentor', 'Mentee']

const PARENT = window.location.host.split('.')[0];

const TABLE_HEAD_STATE = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'regions', label: '# Regions', alignRight: false },
  { id: 'xxx', label: '', alignRight: false },
  { id: 'xx', label: '', alignRight: false },
  { id: 'x', label: '', alignRight: false },
  { id: '' },
];

const TABLE_HEAD_REGION = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'manager', label: 'Regional Director', alignRight: false },
  { id: 'xxx', label: '', alignRight: false },
  { id: 'xx', label: '', alignRight: false },
  { id: 'x', label: '', alignRight: false },
  { id: '' },
];

const TABLE_HEAD_OFFICE = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'manager', label: 'Office Manager', alignRight: false },
  { id: 'xxx', label: '', alignRight: false },
  { id: 'xx', label: '', alignRight: false },
  { id: 'x', label: '', alignRight: false },
  { id: '' },
];

const TABLE_HEAD_OFFICEM = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'xxxx', label: '', alignRight: false },
  { id: 'xxx', label: '', alignRight: false },
  { id: 'xx', label: '', alignRight: false },
  { id: 'x', label: '', alignRight: false },
  { id: '' },
];

const TABLE_HEAD_MENTOR = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'mentees', label: '# Mentees', alignRight: false },
  { id: 'xxx', label: '', alignRight: false },
  { id: 'xx', label: '', alignRight: false },
  { id: 'x', label: '', alignRight: false },
  { id: '' },
];

const TABLE_HEAD_MENTEE = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'sales', label: 'Sales ($)', alignRight: false },
  { id: 'start', label: 'Date Started', alignRight: false },
  { id: 'quartc', label: 'Quarters Complete', alignRight: false },
  { id: 'x', label: '', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function formatNumberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function getElementName(element, onPage) {
  if (onPage === 0) {
    return (element[0]);
  }
  if (onPage === 1) {
    return (element[1]);
  }
  if (onPage === 2) {
    return (element[1]);
  }
  if (onPage === 3) {
    return (`${element[1]} ${element[2]}`);
  }
  return (`${element[1]} ${element[2]}`);
}


function getQCompleted(curriculum) {
  const qComplete = [];

  const q1 = [];
  const q2 = [];
  const q3 = [];
  const q4 = [];

  curriculum.forEach((section) => {
      if (section.quarter === 1) {
          if (section.checks.every(check => check.checked)) {
              q1.push(1)
          } else {
              q1.push(0)
          }
      } else if (section.quarter === 2) {
          if (section.checks.every(check => check.checked)) {
              q2.push(1)
          } else {
              q2.push(0)
          }
      } else if (section.quarter === 3) {
          if (section.checks.every(check => check.checked)) {
              q3.push(1)
          } else {
              q3.push(0)
          }
      } else if (section.quarter === 4) {
          if (section.checks.every(check => check.checked)) {
              q4.push(1)
          } else {
              q4.push(0)
          }
      }
  })

  if (q1.every(val => val === 1)) {
      qComplete.push(1);
  }
  if (q2.every(val => val === 1)) {
      qComplete.push(2);
  }
  if (q3.every(val => val === 1)) {
      qComplete.push(3);
  }
  if (q4.every(val => val === 1)) {
      qComplete.push(4);
  }
  
  return qComplete;
}

export default function Mainpage() {
  const [accountType, setAccountType] = useState("");
  const [userData, setUserData] = useState({});

  const [anchorEl, setAnchorEl] = useState([]);
  const [newOpen, setNewOpen] = useState(false);
  const [newManagerOpen, setNewManagerOpen] = useState(false);
  const [viewManagersOpen, setViewManagersOpen] = useState(false);

  const [menteeModalView, setMenteeModalView] = useState(false);
  const [menteeToView, setMenteeToView] = useState("");

  const [menuOpenOnVal, setMenuOpenOnVal] = useState([]);
  const [managerOpenOnVal, setManagerOpenOnVal] = useState('');

  const loc = useLocation(); 
  const params = useParams();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [loading, setLoading] = useState([]);
  const [currentList, setCurrentList] = useState([]);
  const [collectionName, setCollectionName] = useState('');

  const [onPage, setOnPage] = useState(0);
  const [location, setLocation] = useState([]);
  const [locationLabels, setLocationLabels] = useState([]);

  const [isOfficeManager, setIsOfficeManager] = useState(false);
  const [officeManagerList, setOfficeManagerList] = useState([]);

  const [editNameOpen, setEditNameOpen] = useState(false);

  const [managerToDelete, setManagerToDelete] = useState([]);

  async function actuallyDeleteManager() {
    setLoading(true);
    const mLoc = [...location, managerToDelete[1]];
    await deleteUser({uid : managerToDelete[0], 
      parent: location[0], 
      userLocation: mLoc, 
      accountType: "Regional Manager",
    }).then(() => {setManagerToDelete([])});
    
    enforcePermissions(accountType, userData);
  }

  function setRManagerToDelete(manager, region) {
    setManagerToDelete([manager, region]);
  }

  const { user } = UserAuth();

  function errToast(text) {
    toast.error(text, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      });
  }

  function addNewManager(val) {
    setManagerOpenOnVal(val);
    setNewManagerOpen(true)
  }

  function addOfficeManager() {
    setViewManagersOpen(false);
    setNewManagerOpen(true);
  }

  function openViewManagers(val) {
    setManagerOpenOnVal(val);
    setViewManagersOpen(true);
  }

  async function navigateTo(key) {
    if (key === onPage) {
      return;
    }
    if (key > location.length) {
      return;
    }

    if (key === 0) {
      navigate('/dashboard/states');
    }
    else if (key === 1) {
      navigate(`/dashboard/states/${params.stateID}/regions`);
    }
    else if (key === 2) {
      navigate(`/dashboard/states/${params.stateID}/regions/${params.regionID}/offices`);
    }
    else if (key === 3) {
      navigate(`/dashboard/states/${params.stateID}/regions/${params.regionID}/offices/${params.officeID}/mentors`);
    }
  }

  async function handleDelete() {
    if (onPage === 0) {
      const stateRef = doc(db, "Companies", location[0], "States", menuOpenOnVal[0]);
      const stateSnap = await getDoc(stateRef);
      if (stateSnap.data().subCount > 0) {
        errToast("Cannot delete this state as there are still regions in it.")
      } else {
        await deleteDoc(stateRef).then(() => {
          handleCloseMenu();
        })
      }
    }
    else if (onPage === 1) {
      const itemRef = doc(db, "Companies", location[0], "States", location[1], "Regions", menuOpenOnVal[0]);
      const itemSnap = await getDoc(itemRef);
      console.log(itemSnap.data())
      if (itemSnap.data().subCount > 0) {
        errToast("Cannot delete this region as there are still offices in it.")
      }
      else {
        await deleteDoc(itemRef).then(() => {
          handleCloseMenu();
        })
        const stateRef = doc(db, "Companies", location[0], "States", location[1]);
        const stateSnap = await getDoc(stateRef);
        await updateDoc(stateRef, {
          subCount: stateSnap.data().subCount - 1
        })
      }
    }
    else if (onPage === 2) {
      const itemRef = doc(db, "Companies", location[0], "States", location[1], "Regions", location[2], "Offices", menuOpenOnVal[0]);
      const itemSnap = await getDoc(itemRef);
      console.log(itemSnap.data())
      if (itemSnap.data().subCount > 0) {
        errToast("Cannot delete this office as there are still mentors in it.")
      }
      else {
        await deleteDoc(itemRef).then(() => {
          handleCloseMenu();
        })
        const regionRef = doc(db, "Companies", location[0], "States", location[1], "Regions", location[2]);
        const regionSnap = await getDoc(regionRef);
        await updateDoc(regionRef, {
          subCount: regionSnap.data().subCount - 1
        })
      }
    }
    else if (onPage === 3) {
      const itemRef = doc(db, "Companies", location[0], "States", location[1], "Regions", location[2], "Offices", location[3], "Mentors", menuOpenOnVal[0]);
      const itemSnap = await getDoc(itemRef);
      console.log(itemSnap.data())
      if (itemSnap.data().MenteeCount > 0) {
        errToast("Cannot delete this mentor as there are still mentees within in it.")
      }
      else {
        await deleteUser({uid : menuOpenOnVal[0], 
          parent: location[0], 
          userLocation: location, 
          accountType: "Mentor",
        });
      }
    }
    else if (onPage === 4) {
      const mentorRef = doc(db, "Companies", location[0], "States", location[1], "Regions", location[2], "Offices", location[3], "Mentors", location[4]);
      const mentorSnap = await getDoc(mentorRef);
      await deleteDoc(doc(db, "Companies", location[0], "States", location[1], "Regions", location[2], "Offices", location[3], "Mentors", location[4], "Mentees", menuOpenOnVal[0])).then(() => {
        handleCloseMenu();
      })
      await updateDoc(mentorRef, {
        MenteeCount: mentorSnap.data().MenteeCount - 1
      })
      const companySnap = await getDoc(doc(db, "Companies", location[0]));
      const tempMenteeList = companySnap.data().menteeCache;
      // menteeCache is an array of objects with the mentee's uid and other data. this is marked by id. remove the entry with the matching id
      tempMenteeList.splice(tempMenteeList.findIndex((obj => obj.id === menuOpenOnVal[0])), 1);
      await updateDoc(doc(db, "Companies", location[0]), {
        menteeCache: tempMenteeList
      })
    }

    enforcePermissions(accountType, userData);
  }

  async function retrieveData() {
    if (accountType === "") {
      const userSnap = await getDoc(doc(db, "Companies", PARENT, "Users", user.uid));
      user.getIdTokenResult().then(async (idTokenResult) => {
        enforcePermissions(idTokenResult.claims.accountType, userSnap.data());
        setUserData(userSnap.data());
        setAccountType(idTokenResult.claims.accountType);
      });
    } else {
      enforcePermissions(accountType, userData);
    }
  }

  async function enforcePermissions(type, userData) {
    if (type === "Regional Manager") {
      if (!params.regionID) {
        navigate(`/dashboard/states/${userData.location[1]}/regions/${userData.location[2]}/offices`);
      }
    }
    else if (type === "Office Manager") {
      if (!params.regionID) {
        navigate(`/dashboard/states/${userData.location[1]}/regions/${userData.location[2]}/offices`);
      }
    }
    else if (type === "Mentor") {
      if (!params.mentorID) {
        navigate(`/dashboard/states/${userData.location[1]}/regions/${userData.location[2]}/offices/${userData.location[3]}/mentors/${user.uid}/mentees`);
      }
    }

    if (type === "Office Manager") {
        setIsOfficeManager(true);
        const userManagerSnap = await getDoc(doc(db, "Companies", userData.location[0], "Users", user.uid));
        setOfficeManagerList(userManagerSnap.data().myOffices);
        setAccountType(type);
        syncPage(type, userManagerSnap.data().myOffices);
    } else {
      setAccountType(type);
      syncPage(type, []);
    }
  }

  async function syncPage(type, myOffices) {
    const p0 = PARENT && !params.stateID;
    const p1 = params.stateID && !params.regionID;
    const p2 = params.regionID && !params.officeID;
    const p3 = params.officeID && !params.mentorID;
    const p4 = params.mentorID;

    if (p0 && (type === "Admin" || type === "Viewer")) {
      const states = await getDocs(collection(db, "Companies", PARENT, "States"));
      const regionList = [];
      states.forEach((doc) => {
          regionList.push([doc.id, doc.data().subCount]);
      });
      setCurrentList(regionList);
      setLocation([PARENT]);
      setLocationLabels([PARENT]);
      setOnPage(0);
    } 
    else if (p1 && (type === "Admin" || type === "Viewer")) {
      const regions = await getDocs(collection(db, "Companies", PARENT, "States", params.stateID, "Regions"))
      const regionList = [];
      await Promise.all(
        regions.docs.map(async (regionDoc) => {
          if (regionDoc.data().Manager !== 'N/A') {
            const managerSnap = await getDoc(doc(db, "Companies", PARENT, "Users", regionDoc.data().Manager));
            const hasLoggedIn = managerSnap.data().hasLoggedIn !== undefined;
            regionList.push([regionDoc.id, regionDoc.data().Label, [regionDoc.data().Manager, regionDoc.data().ManagerLabel, regionDoc.data().subCount, hasLoggedIn]]);
          } else {
          regionList.push([regionDoc.id, regionDoc.data().Label, ["N/A", "N/A", "N/A", false]]);
          }
        })
      );
      setCurrentList(regionList);
      setLocation([PARENT, params.stateID]);
      setLocationLabels([PARENT, params.stateID]);
      setOnPage(1);
    }
    else if (p2 && (type === "Regional Manager" || type === "Admin"|| type==="Office Manager" || type === "Viewer")) {
      const regionSnap = await getDoc(doc(db, "Companies", PARENT, "States", params.stateID, "Regions", params.regionID));
      const offices = await getDocs(collection(db, "Companies", PARENT, "States", params.stateID, "Regions", params.regionID, "Offices"));
      const regionList = [];
      offices.forEach((doc) => {
        if (type !== "Office Manager") {
          regionList.push([doc.id, doc.data().Label, [doc.data().Manager, doc.data().ManagerLabel, doc.data().subCount]]);
        } else if (type === "Office Manager" && myOffices.indexOf(doc.id) !== -1){
          regionList.push([doc.id, doc.data().Label, [doc.data().Manager, doc.data().ManagerLabel, doc.data().subCount]]);
        }
      });
      setCurrentList(regionList);
      setLocation([PARENT, params.stateID, params.regionID]);
      setLocationLabels([PARENT, params.stateID, regionSnap.data().Label]);
      setOnPage(2);
    } else if (p3 && type !== "Mentor") {
      const regionSnap = await getDoc(doc(db, "Companies", PARENT, "States", params.stateID, "Regions", params.regionID));
      const officeSnap = await getDoc(doc(db, "Companies", PARENT, "States", params.stateID, "Regions", params.regionID, "Offices", params.officeID));
      const mentors = await getDocs(collection(db, "Companies", PARENT, "States", params.stateID, "Regions", params.regionID, "Offices", params.officeID, "Mentors"))
      const regionList = [];
      await Promise.all(
        mentors.docs.map(async (mentorDoc) => {
          const mentorSnap = await getDoc(doc(db, "Companies", PARENT, "Users", mentorDoc.id));
          const hasLoggedIn = mentorSnap.data().hasLoggedIn !== undefined;
          regionList.push([mentorDoc.id, mentorDoc.data().firstName, mentorDoc.data().lastName, mentorDoc.data().MenteeCount, mentorDoc.data().subCount, hasLoggedIn]);
        })
      )
      setCurrentList(regionList);
      setLocation([PARENT, params.stateID, params.regionID, params.officeID]);
      setLocationLabels([PARENT, params.stateID, regionSnap.data().Label, officeSnap.data().Label]);
      setOnPage(3);
    } else if (p4) {
      const regionSnap = await getDoc(doc(db, "Companies", PARENT, "States", params.stateID, "Regions", params.regionID));
      const officeSnap = await getDoc(doc(db, "Companies", PARENT, "States", params.stateID, "Regions", params.regionID, "Offices", params.officeID));
      const mentorSnap = await getDoc(doc(db, "Companies", PARENT, "States", params.stateID, "Regions", params.regionID, "Offices", params.officeID, "Mentors", params.mentorID));
      const mentees = await getDocs(collection(db, "Companies", PARENT, "States", params.stateID, "Regions", params.regionID, "Offices", params.officeID, "Mentors", params.mentorID, "Mentees"));
      const regionList = [];
      mentees.forEach((doc) => {
        regionList.push([doc.id, doc.data().mFirstName, doc.data().mLastName, doc.data().mSales, doc.data().mStart, getQCompleted(doc.data().mCurriculum)]);
      });
      setCurrentList(regionList);
      setLocation([PARENT, params.stateID, params.regionID, params.officeID, params.mentorID]);
      setLocationLabels([PARENT, params.stateID, regionSnap.data().Label, officeSnap.data().Label, mentorSnap.data().lastName]);
      setOnPage(4);
    }
    setLoading([]);
  }

  useEffect(() => {
    retrieveData();
    // detect any changes to the URL
    if (loc.pathname !== params.pathname) {
      retrieveData();
    }
    console.log(currentList);
  }, [loc]);

  function CustomTableCell(props) {
    const cellStyle = {
      borderBottom: '2px solid #333333',
    };

    return <TableCell style={cellStyle} {...props} />;
  };


  const handleOpenMenu = (event, val) => {
    setAnchorEl([event.clientX, event.clientY]);
    setMenuOpenOnVal(val);
    console.log(event);
  };

  const handleCloseMenu = () => {
    setMenuOpenOnVal([]);
    setAnchorEl([0, 0]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = currentList.map((n) => n[0]);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  async function handleRemoveManager() {
    setRManagerToDelete(menuOpenOnVal[2][0], menuOpenOnVal[0])
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    console.log(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  async function viewInto(data) {
    if (!params.mentorID) {
      setLoading(data);
      const val = data[0];

      if (!params.stateID) {
        navigate(`${window.location.pathname}/${val}/regions`);
      }
      else if (!params.regionID) {
        navigate(`${window.location.pathname}/${val}/offices`);
      }
      else if (params.regionID && !params.officeID) {
        navigate(`${window.location.pathname}/${val}/mentors`);
      }
      else if (params.officeID && !params.mentorID) {
        navigate(`${window.location.pathname}/${val}/mentees`);
      }
    } else {
      setMenteeToView(data[0]);
      console.log(data[0]);
      setMenteeModalView(true)
    }
  }

  function closeMenteeModal() {
    setMenteeModalView(false);
    retrieveData();
    setMenteeToView("");
  }

  function addNewClose() {
    enforcePermissions(accountType, userData);
    setNewManagerOpen(false);
    setNewOpen(false);
  }

  function handleOpenEdit() {
    setAnchorEl([0, 0]);
    setEditNameOpen(true);
  }

  function handleCloseEdit() {
    handleCloseMenu();
    setEditNameOpen(false)
    retrieveData();
  }

  return (
    <>
      <Helmet>
        <title> Dashboard | StrongMentorship </title>
      </Helmet>

      {newOpen && (
        <AddNew current={onPage} location={location} closeModal={() => addNewClose()}/>
      )}

      {newManagerOpen && (
        <AddManager current={onPage} role={onPage === 1 ? "Regional Manager" : "Office Manager"} location={[...location, managerOpenOnVal]} closeModal={() => addNewClose()} company={PARENT}/>
      )}

      {editNameOpen && (
        <EditName current={onPage} menuOpenOn={menuOpenOnVal} location={[...location, menuOpenOnVal[0]]} closeModal={() => handleCloseEdit()} company={PARENT}/>
      )}

      {managerToDelete.length > 0 && (
        <div className='AreYouSureModal'>
          Are you sure you want to remove this manager?&nbsp;&nbsp;
          <Button variant="contained" onClick={() => setManagerToDelete([])}>
            No
          </Button>
          <Button onClick={() => actuallyDeleteManager()}>
            Yes
          </Button>
        </div>
      )}

      {viewManagersOpen && (
        <ViewManagers current={onPage} location={[...location, managerOpenOnVal]} closeModal={() => setViewManagersOpen(false)} company={PARENT} addManager={() => addOfficeManager()}/>
      )}

      {menteeModalView && (
        <MenteeModal mentee={menteeToView} role={onPage === 0 ? "Regional Manager" : "Office Manager"} location={location} closeModal={() => closeMenteeModal()} company={PARENT}/>
      )}

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Button variant="contained" 
                  style={{marginLeft:"auto"}} 
                  onClick={() => setNewOpen(true)}
                  startIcon={<Iconify icon="eva:plus-fill" 
                  />}>
            New {ORDER[onPage]}
          </Button>
        </Stack>
          <Breadcrumb style={{marginLeft:"-30px"}}>
          {location.map((val, key) => {
            if (accountType === "Admin" || (accountType === "Regional Manager" && key > 1)
             || (accountType === "Office Manager" && key > 1)) {
              return(
                <BreadcrumbItem>
                  <BreadcrumbLink className="ListRow" textDecoration="none" color="#3E89FF" fontSize={15}
                    onClick={() => navigateTo(key)}>{locationLabels[key][0].toUpperCase() + locationLabels[key].slice(1)}</BreadcrumbLink>
                </BreadcrumbItem>
              );
            }
            return("x");
          })}
          </Breadcrumb>
        <Card>

          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} location={ORDER[onPage]} locLabel={isOfficeManager && onPage === 2 ? "My" : locationLabels[onPage]}/>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={onPage === 0 ? TABLE_HEAD_STATE : onPage === 1 ? TABLE_HEAD_REGION : onPage === 2 && !isOfficeManager ? TABLE_HEAD_OFFICE:  onPage === 2 && isOfficeManager ? TABLE_HEAD_OFFICEM :onPage === 3 ? TABLE_HEAD_MENTOR : TABLE_HEAD_MENTEE}
                  rowCount={currentList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {currentList.filter(element => getElementName(element, onPage)
                  .toLowerCase().includes(filterName.toLocaleLowerCase()))
                  .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage).map((val) => {
                    const selectedUser = selected.indexOf(val[0]) !== -1;
                    return (
                      <TableRow hover key={val[0]} tabIndex={-1} role="checkbox" 
                                selected={selectedUser}>
                        <CustomTableCell padding="checkbox"/>
                        
                        <CustomTableCell component="th" scope="row" padding="none" className="ListRow" onClick={() => viewInto(val)}>
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {val !== loading ? (
                                <>                              
                                  {onPage === 0 && val[0]}
                                  {onPage < 3 && onPage > 0 && val[1]}
                                  {onPage === 3 && (
                                    <div style={val[5] ? {color:"white"} : {color:"yellow"}}>
                                        {`${val[1]} ${val[2]}`}
                                    </div>
                                  )}
                                  {onPage >= 4 && `${val[1]} ${val[2]}`}
                                </>
                              )
                            :
                              <>
                                <PulseLoader speedMultiplier="2" color="#6594e3"/>
                              </>
                            }
                            </Typography>
                          </Stack>
                        </CustomTableCell>

                        <CustomTableCell align="left">
                          {onPage === 1 && val[2][0] === "N/A" && (<>
                            <Button style={{marginLeft:"-20px"}} onClick={() => addNewManager(val[0])}>
                              + Add Director
                            </Button>
                          </>)}
                          {onPage === 2 && val[2][0] !== "N/A" && !isOfficeManager && (<>
                            <Button variant="blue" style={{marginLeft:"-20px"}} onClick={() => openViewManagers(val[0])}>
                              View Managers
                            </Button>
                          </>)}
                          {onPage === 1 && val[2][0] !== "N/A" && (<div style={val[2][3] ? {color:"white"} : {color:"yellow"}}>
                            {val[2][1]} 
                          </div>)}
                           {onPage === 0 && (<>
                            {val[1]}
                          </>)}
                          {onPage === 3 && (<>
                            {val[3]}
                          </>)}
                          {onPage === 4 && (<>
                            ${formatNumberWithCommas(val[3])}
                          </>)}
                        </CustomTableCell>

                        <CustomTableCell align="left">
                          {onPage === 4 && (<>
                            {val[4]}
                          </>)}
                        </CustomTableCell>

                        <CustomTableCell align="left">
                            {onPage === 4 && (<>
                            <div style={{display:"flex"}}>
                                {val[5].map((quart, key) => {
                              if (key === val[5].length - 1) {
                                return(
                                  <>
                                    {quart}
                                  </>
                                );
                              }
                              return (
                                  <div>
                                    {quart},&nbsp;
                                  </div>
                              );
                            })}
                            </div>
                          </>)}
                        </CustomTableCell>

                        <CustomTableCell align="left" />

                        <CustomTableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, val)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </CustomTableCell>
                      </TableRow>
                    );
                  })}

                  
                </TableBody>
                
                {currentList.length === 0 && accountType !== "" && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            No {ORDER[onPage].toLocaleLowerCase()}s yet.
                          </Typography>

                          <Typography variant="body2">
                            Add {ORDER[onPage].toLocaleLowerCase()}s with the blue button above!
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={currentList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={anchorEl[0] >= 40}
        onClose={handleCloseMenu}
        anchorReference="anchorPosition"
        anchorPosition={{ left: anchorEl[0], top: anchorEl[1] }}
        PaperProps={{
          sx: {
            p: 1,
            width: 180,
            '&  .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {onPage > 0 && onPage < 3 && (
          <MenuItem onClick={() => handleOpenEdit()}>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Edit Label
          </MenuItem>
        )}

        {onPage === 1 && menuOpenOnVal[2] !== undefined && menuOpenOnVal[2][0] !== "N/A" && (<MenuItem sx={{ color: 'orange' }} onClick={() => handleRemoveManager()}>
          <PersonRemoveIcon sx={{ mr: 1.4 }}/>
          Remove Manager
        </MenuItem>)}

        <MenuItem sx={{ color: 'error.main' }} onClick={() => handleDelete()}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} 
            />
          Delete Entry
        </MenuItem>
      </Popover>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        />
    </>
  );
}
