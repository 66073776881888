import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDoc, doc } from 'firebase/firestore';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ToastContainer, toast } from 'react-toastify';
// components
import { UserAuth } from '../../../contexts/AuthContext';
import Iconify from '../../../components/iconify';
import { db } from '../../../firebase';

// ----------------------------------------------------------------------
const PARENT = window.location.host.split('.')[0];

export default function ResetForm() {
  const auth = getAuth();
  const [email, setEmail] = useState('');
  const [sent, setSent] = useState(false);

  function handleClick() {
    const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: `https://${PARENT.toLowerCase()}.strongmentorship.com`,
        // This must be true.
        handleCodeInApp: true,
        expireAfterSeconds: 259200
    };
    sendPasswordResetEmail(auth, email, actionCodeSettings)
    .then(() => {
        // Password reset email sent!
        // ..
    })
    .catch((error) => {
        const errorCode = error.code;
        console.log(errorCode);
        const errorMessage = error.message;
        console.log(errorMessage);
        // ..
    });
    setEmail('');
    setSent(true);
    toast.success('If an account is associated with this email, you should receive a reset email shortly.', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
  }

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email"
                   label="Email"
                   value={email}
                   onChange={e => setEmail(e.target.value)}/>
      </Stack>

      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link variant="subtitle2" underline="hover" onClick={() => navigate('/reset')}>
          Forgot password?
        </Link>
      </Stack> */}
      <br />

      {email !== "" && sent === false?
      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={() => handleClick()}>
        Send Reset Email
      </LoadingButton>
      :
      <LoadingButton fullWidth size="large" type="submit" variant="contained" disabled>
        Send Reset Email
      </LoadingButton>
      }
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}
