import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDoc, updateDoc, doc } from 'firebase/firestore';
import { PuffLoader } from 'react-spinners';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, FormControl, RadioGroup, FormLabel, FormControlLabel, Radio } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { applyEmailHTML } from './ApplyEmailHTML';
// components
import { UserAuth } from '../../../contexts/AuthContext';
import Iconify from '../../../components/iconify';
import { db, sendEmail } from '../../../firebase';

// ----------------------------------------------------------------------
const PARENT = window.location.host.split('.')[0];

export default function ApplyForm(props) {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const { signIn } = UserAuth();

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [office, setOffice] = useState('');

  const [company, setCompany] = useState('');

  const [mState, setMState] = useState('');
  const [eState, setEState] = useState('');

  const [m1, setM1] = useState('');
  const [m2, setM2] = useState('');

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);

  const {user, logout} = UserAuth();

  const handleLogout = async () => {
    try {
    await updateDoc(doc(db, "Companies", PARENT, "Users", user.uid), {
      isLoggedIn: false,
    });
    await logout();
    navigate('/');
    console.log('You are logged out')
    } catch (e) {
    console.log(e.message);
    }
  };

  async function handleSubmit() {
    const emailBody = applyEmailHTML(name, email, company, office, mState, eState, m1, m2);
    setLoading(true);
    await sendEmail({ 
        fromEmail: '"StrongMentorship" <info@strongmentorship.com>',
        toEmail: 'admin@strongmentorship.com',
        subject: "New Mentorship Application!",
        emailHTML: emailBody,
    }).then(() => {
      props.finished();
    });
  }

  useEffect(() => {
    if (user) {
      handleLogout();
    }
  }, [])

  const handleClick = () => {
    // if (email !== "" && password !== "") {
    //   handleSubmit();
    // }
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="name"
                   label="Full Name"
                   onChange={e => setName(e.target.value)} />

        <TextField name="email"
                   label="Email address"
                   onChange={e => setEmail(e.target.value)} />
        <FormLabel id="demo-row-radio-buttons-group-label">What company do you currently work for (leave blank if Compass)?</FormLabel>
        <TextField name="company"
                   label="Company Name"
                   onChange={e => setCompany(e.target.value)} />

        <FormLabel id="demo-row-radio-buttons-group-label">What Compass office are you currently at / desire to be at?</FormLabel>
        <TextField name="office"
                   label="Office Name"
                   onChange={e => setOffice(e.target.value)} />

        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">Would you like to be a mentor or mentee?</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel value="mentor" control={<Radio />} label="Mentor" onChange={(e) => setMState(e.target.value)}/>
              <FormControlLabel value="mentee" control={<Radio />} label="Mentee" onChange={(e) => setMState(e.target.value)}/>
            </RadioGroup>
        </FormControl>

        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">How many years of experience do you currently have?</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel value="0-1" control={<Radio />} label="0-1" onChange={(e) => setEState(e.target.value)}/>
              <FormControlLabel value="2-4" control={<Radio />} label="2-4" onChange={(e) => setEState(e.target.value)}/>
              <FormControlLabel value="5-9" control={<Radio />} label="5-9" onChange={(e) => setEState(e.target.value)}/>
              <FormControlLabel value="10+" control={<Radio />} label="10+" onChange={(e) => setEState(e.target.value)}/>
            </RadioGroup>
        </FormControl>

        <FormLabel id="demo-row-radio-buttons-group-label">How did you hear about us?</FormLabel>
        <TextField id="standard-multiline-static"
          label="Message"
          multiline
          rows={7}
          style={{width:"100%"}}
          onChange={e => setM1(e.target.value)}
          value={m1} />

        <FormLabel id="demo-row-radio-buttons-group-label">What do you hope to achieve in our mentorship program?</FormLabel>
        <TextField id="standard-multiline-static"
          label="Message"
          multiline
          rows={7}
          style={{width:"100%"}}
          onChange={e => setM2(e.target.value)}
          value={m2} />
      </Stack>

      <br />

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={() => handleSubmit()}
      disabled={
        name==="" || 
        email ==="" || 
        office ==="" || 
        mState ==="" || 
        eState ==="" || 
        m1 ==="" || 
        m2 ===""}>
        {loading ?
        <PuffLoader color="#ffffff" size="40px"/>
        :
        "Submit"}
      </LoadingButton>
      <div style={{marginTop:"10px", color:"red"}}>
        {error}
      </div>
    </>
  );
}
